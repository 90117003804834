export const drawLines = (context: any, points: any, color: string) => {
  if (points.length < 2) {
    return;
  }

  context.beginPath();
  context.moveTo(points[0].x, points[0].y);

  for (let i = 1; i < points.length - 2; i += 1) {
    const xc = (points[i].x + points[i + 1].x) / 2;
    const yc = (points[i].y + points[i + 1].y) / 2;

    context.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
  }

  const xc = (points[points.length - 1].x + points[0].x) / 2;
  const yc = (points[points.length - 1].y + points[0].y) / 2;

  context.quadraticCurveTo(
    points[points.length - 1].x,
    points[points.length - 1].y,
    xc,
    yc
  );

  context.strokeStyle = color;
  context.globalAlpha = 1;
  context.lineWidth = 2;
  context.stroke();
};
