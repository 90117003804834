import React from "react";
import ProductItem from "../ProductItem/index";
import { Typography, useMediaQuery } from "@mui/material";

interface IProductList {
  products: any;
}

const ProductList: React.FC<IProductList> = ({ products }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return (
    <div style={{ marginTop: 50, backgroundColor: "#EEEDE8", width: "100%", paddingBottom: 60, }}>
      <Typography
        variant="h5"
        sx={{ textAlign: "center", fontFamily: "'neue_hans_kendrickregular'", marginBottom: 3, fontWeight: "bold", paddingTop: 5 }}
      >
        Product Recommendation
      </Typography>
      <Typography
        variant={isSmallScreen ? "h5" : "h4"}
        sx={{ textAlign: "center", fontFamily: "'neue_hans_kendrickregular'" }}
      >
        Based on your Skin Score
      </Typography>
      <Typography
        variant={isSmallScreen ? "h5" : "h4"}
        sx={{ textAlign: "center", fontFamily: "'neue_hans_kendrickregular'", marginBottom: 3 }}
      >
        these are your must-haves
      </Typography>
      {products.map((product: any) => (
        <ProductItem key={product.product.id} product={product} />
      ))}
    </div>
  );
};

export default ProductList;
