import React from "react";
import { Container, Typography, styled } from "@mui/material";
import CustomBox from "../../CustomBox";

const OverlayContainer = styled(Container)({
  textAlign: "center",
  color: "white",
  fontSize: "18px",
});

const CountdownText = styled(Typography)({
  color: "white",
  fontSize: "80px",
  fontWeight: "bold",
  position: "absolute",
  top: "47%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textShadow: "2px 2px 2px black",
});

const FaceShapeContainer = styled(Container)(({ theme }) => ({
  width: "275px",
  height: "375px",
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  pointerEvents: "none",
  zIndex: 1000,
  overflow: "hidden",
  border: "2px solid white",
  borderRadius: "50%",
  backdropFilter: "blur(-50px)",

  [theme.breakpoints.down("sm")]: {
    top: "60%",
  },
}));
const CameraLayoutText = styled(Container)(({ theme }) => ({
  position: "absolute",
  left: 0,
  top: "10%",
  [theme.breakpoints.down("sm")]: {
    top: "15%",
  },
}));

interface ICameraLayout {
  countdownActive: boolean;
  seconds: any;
  isCameraVisible: boolean;
  isFaceInShape: any;
  isStraight: any;
  isLightGood: any;
}

const CameraLayout: React.FC<ICameraLayout> = ({
  countdownActive,
  seconds,
  isCameraVisible,
  isFaceInShape,
  isStraight,
  isLightGood,
}) => {
  return (
    <OverlayContainer>
      <Typography
        sx={{
          color: "white",
          fontSize: "18px",
          fontWeight: "bold",
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textShadow: "2px 2px 2px black",
        }}
      >
        {countdownActive ? "Take photo in:" : null}
      </Typography>
      {countdownActive ? <CountdownText>{seconds}</CountdownText> : null}
      {!countdownActive && isCameraVisible ? (
        <>
          <FaceShapeContainer />
          <CameraLayoutText>
            <div
              style={{
                display: "flex",
                maxWidth: "380px",
                justifyContent: "center",
                alignItems: "center",
                margin: "0px auto 0px auto",
                marginTop: "20px",
              }}
            >
              <CustomBox text="Lighting:" dynamicText={isLightGood} />
              <CustomBox text="Look straight:" dynamicText={isStraight} />
              <CustomBox text="Face position:" dynamicText={isFaceInShape} />
            </div>
          </CameraLayoutText>
        </>
      ) : null}
    </OverlayContainer>
  );
};

export default CameraLayout;
