export function isFaceAlignedAndStraight(faceLandmarks: any) {
  if (!faceLandmarks) return "bad";
  const landmarks = faceLandmarks;
  const leftEyeInner = landmarks.getLeftEye()[0];
  const rightEyeInner = landmarks.getRightEye()[0];
  const leftEyeOuter = landmarks.getLeftEye()[3];
  const rightEyeOuter = landmarks.getRightEye()[5];

  if (!leftEyeInner || !rightEyeInner || !leftEyeOuter || !rightEyeOuter)
    return "bad";

  const angleLeftEye = Math.atan2(
    leftEyeInner.y - leftEyeOuter.y,
    leftEyeInner.x - leftEyeOuter.x
  );

  const angleRightEye = Math.atan2(
    rightEyeInner.y - rightEyeOuter.y,
    rightEyeInner.x - rightEyeOuter.x
  );

  const angleDifference =
    Math.abs(angleLeftEye - angleRightEye) * (180 / Math.PI);

  if (angleDifference <= 50) {
    return "good"; 
  }  else {
    return "bad"; 
  }
}
