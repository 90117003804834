export const xmlData = `<?xml version="1.0" encoding="UTF-8" ?>
<feed xmlns="http://www.w3.org/2005/Atom" xmlns:g="http://base.google.com/ns/1.0">

<title><![CDATA[Avant Skincare GB]]></title>
<link rel="self" href="https://avant-skincare.com/"/>
<updated>2024-03-05T09:08:48Z</updated>
<entry>
<g:id>60</g:id>
<g:title><![CDATA[Advanced Bio Absolute Youth Eye Therapy]]></g:title>
<g:description><![CDATA[A delicate eye serum designed to visibly enhance the skin condition around the eye area.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/eye-care/60-advanced-bio-absolute-youth-eye-therapy-647732736175-5060762540003.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/695-large_default/advanced-bio-absolute-youth-eye-therapy-647732736175.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/547-large_default/advanced-bio-absolute-youth-eye-therapy-647732736175.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/548-large_default/advanced-bio-absolute-youth-eye-therapy-647732736175.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/910-large_default/advanced-bio-absolute-youth-eye-therapy-647732736175.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1040-large_default/advanced-bio-absolute-youth-eye-therapy-647732736175.jpg]]></g:additional_image_link>
<g:price>89.00 GBP</g:price>
<g:sale_price>66.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Eye Care]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540003]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>61</g:id>
<g:title><![CDATA[Advanced Bio Radiance Invigorating Concentrate Serum]]></g:title>
<g:description><![CDATA[An invigorating serum that works to firm and protect your skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/serums/61-advanced-bio-radiance-invigorating-concentrate-serum-647732736182-5060762540010.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1679-large_default/advanced-bio-radiance-invigorating-concentrate-serum-647732736182.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/549-large_default/advanced-bio-radiance-invigorating-concentrate-serum-647732736182.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/550-large_default/advanced-bio-radiance-invigorating-concentrate-serum-647732736182.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/911-large_default/advanced-bio-radiance-invigorating-concentrate-serum-647732736182.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1080-large_default/advanced-bio-radiance-invigorating-concentrate-serum-647732736182.jpg]]></g:additional_image_link>
<g:price>94.00 GBP</g:price>
<g:sale_price>70.50 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Serums]]></g:product_type>
<g:gtin><![CDATA[5060762540010]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>64</g:id>
<g:title><![CDATA[Advanced Bio Regenerating Overnight Treatment]]></g:title>
<g:description><![CDATA[The Advanced Bio Regenerating Overnight Treatment is an invigorating night treatment aimed at restoring your skin's natural balance.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/night-creams/64-advanced-bio-regenerating-overnight-treatment-5060762540041-5060762540041.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/699-large_default/advanced-bio-regenerating-overnight-treatment-5060762540041.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/555-large_default/advanced-bio-regenerating-overnight-treatment-5060762540041.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/556-large_default/advanced-bio-regenerating-overnight-treatment-5060762540041.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/915-large_default/advanced-bio-regenerating-overnight-treatment-5060762540041.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1042-large_default/advanced-bio-regenerating-overnight-treatment-5060762540041.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:sale_price>63.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Night creams]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540041]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.090 匁</g:shipping_weight>
</entry>

<entry>
<g:id>65</g:id>
<g:title><![CDATA[LIMITED EDITION Advanced Bio Radiance Invigorating Concentrate Serum]]></g:title>
<g:description><![CDATA[An invigorating serum presenting the purest Hyaluronic Acid and the highest concentration of Matrixyl on the market, working to firm and protect your skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/serums/65-limited-edition-advanced-bio-radiance-invigorating-concentrate-serum-637665736687-5060762540058.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/700-large_default/limited-edition-advanced-bio-radiance-invigorating-concentrate-serum-637665736687.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/557-large_default/limited-edition-advanced-bio-radiance-invigorating-concentrate-serum-637665736687.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/558-large_default/limited-edition-advanced-bio-radiance-invigorating-concentrate-serum-637665736687.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/916-large_default/limited-edition-advanced-bio-radiance-invigorating-concentrate-serum-637665736687.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1082-large_default/limited-edition-advanced-bio-radiance-invigorating-concentrate-serum-637665736687.jpg]]></g:additional_image_link>
<g:price>119.00 GBP</g:price>
<g:sale_price>89.25 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Serums]]></g:product_type>
<g:gtin><![CDATA[5060762540058]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>66</g:id>
<g:title><![CDATA[LIMITED EDITION Advanced Bio Absolute Youth Eye Therapy]]></g:title>
<g:description><![CDATA[Global Makeup Awards 2021 - Best Vegan Eye Product Bronze Winner, this delicate eye serum is designed with the highest concentration of Matrixyl 3000 on the market and the purest Hyaluronic Acid to visibly enhance the skin condition around the eye area.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/eye-care/66-limited-edition-advanced-bio-absolute-youth-eye-therapy-637665736694-5060762540065.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1686-large_default/limited-edition-advanced-bio-absolute-youth-eye-therapy-637665736694.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1562-large_default/limited-edition-advanced-bio-absolute-youth-eye-therapy-637665736694.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/559-large_default/limited-edition-advanced-bio-absolute-youth-eye-therapy-637665736694.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/560-large_default/limited-edition-advanced-bio-absolute-youth-eye-therapy-637665736694.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1033-large_default/limited-edition-advanced-bio-absolute-youth-eye-therapy-637665736694.jpg]]></g:additional_image_link>
<g:price>109.00 GBP</g:price>
<g:sale_price>81.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Eye Care]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540065]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.090 匁</g:shipping_weight>
</entry>

<entry>
<g:id>67</g:id>
<g:title><![CDATA[LIMITED EDITION Advanced Bio Restorative Superfood Facial Oil (Anti-Ageing)]]></g:title>
<g:description><![CDATA[The Advanced Bio Restorative Superfood Facial Oil combines a replenishing elixir of oils and papaya extract to help visibly restore your skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/facial-oils/67-limited-edition-advanced-bio-restorative-superfood-facial-oil-647297413009-5060762540072.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/713-large_default/limited-edition-advanced-bio-restorative-superfood-facial-oil-647297413009.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/584-large_default/limited-edition-advanced-bio-restorative-superfood-facial-oil-647297413009.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/583-large_default/limited-edition-advanced-bio-restorative-superfood-facial-oil-647297413009.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/932-large_default/limited-edition-advanced-bio-restorative-superfood-facial-oil-647297413009.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1041-large_default/limited-edition-advanced-bio-restorative-superfood-facial-oil-647297413009.jpg]]></g:additional_image_link>
<g:price>109.00 GBP</g:price>
<g:sale_price>81.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Facial oils]]></g:product_type>
<g:gtin><![CDATA[5060762540072]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>68</g:id>
<g:title><![CDATA[Deluxe Hyaluronic Acid Vivifying Face & Eye Night Cream]]></g:title>
<g:description><![CDATA[A delightful anti-ageing moisturiser for use on the face and targeted eye area, formulated with Hyaluronic Acid that works to deeply nourish your skin. It also combines signature ingredients to help improve the appearance of enlarged pores, uneven skin tone, fine lines, dullness and a weakened skin surface.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/night-creams/68-deluxe-hyaluronic-acid-vivifying-face-eye-night-cream-5060762540089-5060762540089.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/702-large_default/deluxe-hyaluronic-acid-vivifying-face-eye-night-cream-5060762540089.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/561-large_default/deluxe-hyaluronic-acid-vivifying-face-eye-night-cream-5060762540089.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/562-large_default/deluxe-hyaluronic-acid-vivifying-face-eye-night-cream-5060762540089.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/918-large_default/deluxe-hyaluronic-acid-vivifying-face-eye-night-cream-5060762540089.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1083-large_default/deluxe-hyaluronic-acid-vivifying-face-eye-night-cream-5060762540089.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1988-large_default/deluxe-hyaluronic-acid-vivifying-face-eye-night-cream-5060762540089.jpg]]></g:additional_image_link>
<g:price>97.00 GBP</g:price>
<g:sale_price>72.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Night creams]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540089]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>69</g:id>
<g:title><![CDATA[Ultimate Hyaluronic Acid Resurfacing DUO Moisturiser]]></g:title>
<g:description><![CDATA[One of the best sellers at Avant, this age-defying, resurfacing cream is designed to be used day or night, combining signature ingredients to help illuminate dull skin and lessen the appearance of wrinkles and fine lines.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/moisturisers-mists/69-ultimate-hyaluronic-acid-resurfacing-duo-moisturiser-5060762540287-5060762540287.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/703-large_default/ultimate-hyaluronic-acid-resurfacing-duo-moisturiser-5060762540287.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/564-large_default/ultimate-hyaluronic-acid-resurfacing-duo-moisturiser-5060762540287.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/563-large_default/ultimate-hyaluronic-acid-resurfacing-duo-moisturiser-5060762540287.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/919-large_default/ultimate-hyaluronic-acid-resurfacing-duo-moisturiser-5060762540287.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1085-large_default/ultimate-hyaluronic-acid-resurfacing-duo-moisturiser-5060762540287.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1900-large_default/ultimate-hyaluronic-acid-resurfacing-duo-moisturiser-5060762540287.jpg]]></g:additional_image_link>
<g:price>99.00 GBP</g:price>
<g:sale_price>74.25 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Moisturisers & Mists]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540287]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>70</g:id>
<g:title><![CDATA[Pro-Intense Hyaluronic Acid Illuminating Day Cream]]></g:title>
<g:description><![CDATA[An Age Radiance moisturiser designed to intensify the illumination of your skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/moisturisers-mists/70-pro-intense-hyaluronic-acid-illuminating-day-cream-5060762540195-5060762540195.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/704-large_default/pro-intense-hyaluronic-acid-illuminating-day-cream-5060762540195.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/566-large_default/pro-intense-hyaluronic-acid-illuminating-day-cream-5060762540195.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/783-large_default/pro-intense-hyaluronic-acid-illuminating-day-cream-5060762540195.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/920-large_default/pro-intense-hyaluronic-acid-illuminating-day-cream-5060762540195.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1034-large_default/pro-intense-hyaluronic-acid-illuminating-day-cream-5060762540195.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1987-large_default/pro-intense-hyaluronic-acid-illuminating-day-cream-5060762540195.jpg]]></g:additional_image_link>
<g:price>97.00 GBP</g:price>
<g:sale_price>72.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Moisturisers & Mists]]></g:product_type>
<g:gtin><![CDATA[5060762540195]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>71</g:id>
<g:title><![CDATA[Supreme Hyaluronic Acid Anti-oxidising DUO Moisturiser]]></g:title>
<g:description><![CDATA[An Age Nutri-Revive anti-oxidising moisturiser formulated with our signature Hyaluronic Acid. It aims to restore your skin's natural glow during the day and night.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/moisturisers-mists/71-supreme-hyaluronic-acid-anti-oxidising-duo-moisturiser-5060762540096-5060762540096.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/705-large_default/supreme-hyaluronic-acid-anti-oxidising-duo-moisturiser-5060762540096.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/567-large_default/supreme-hyaluronic-acid-anti-oxidising-duo-moisturiser-5060762540096.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/568-large_default/supreme-hyaluronic-acid-anti-oxidising-duo-moisturiser-5060762540096.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/921-large_default/supreme-hyaluronic-acid-anti-oxidising-duo-moisturiser-5060762540096.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1035-large_default/supreme-hyaluronic-acid-anti-oxidising-duo-moisturiser-5060762540096.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1989-large_default/supreme-hyaluronic-acid-anti-oxidising-duo-moisturiser-5060762540096.jpg]]></g:additional_image_link>
<g:price>99.00 GBP</g:price>
<g:sale_price>74.25 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Moisturisers & Mists]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540096]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>72</g:id>
<g:title><![CDATA[8 hour Radiance Renewal Sleeping Mask]]></g:title>
<g:description><![CDATA[A sleeping mask that works to reveal more youthful and beautiful skin overnight.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/masks-exfoliators/72-8-hour-radiance-renewal-sleeping-mask-647297414143-5060762540201.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1680-large_default/8-hour-radiance-renewal-sleeping-mask-647297414143.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1241-large_default/8-hour-radiance-renewal-sleeping-mask-647297414143.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/922-large_default/8-hour-radiance-renewal-sleeping-mask-647297414143.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1381-large_default/8-hour-radiance-renewal-sleeping-mask-647297414143.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1685-large_default/8-hour-radiance-renewal-sleeping-mask-647297414143.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1036-large_default/8-hour-radiance-renewal-sleeping-mask-647297414143.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1985-large_default/8-hour-radiance-renewal-sleeping-mask-647297414143.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:sale_price>63.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Masks & Exfoliators]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540201]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>73</g:id>
<g:title><![CDATA[Anti-Ageing Glycolic Lifting Face & Neck Mask]]></g:title>
<g:description><![CDATA[The ideal face and neck mask that works overnight to rejuvenate your skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/masks-exfoliators/73-anti-ageing-glycolic-lifting-face-neck-mask-647297414150-5060762540294.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/707-large_default/anti-ageing-glycolic-lifting-face-neck-mask-647297414150.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/571-large_default/anti-ageing-glycolic-lifting-face-neck-mask-647297414150.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/572-large_default/anti-ageing-glycolic-lifting-face-neck-mask-647297414150.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/923-large_default/anti-ageing-glycolic-lifting-face-neck-mask-647297414150.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1037-large_default/anti-ageing-glycolic-lifting-face-neck-mask-647297414150.jpg]]></g:additional_image_link>
<g:price>89.00 GBP</g:price>
<g:sale_price>66.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Masks & Exfoliators]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540294]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>74</g:id>
<g:title><![CDATA[Moisture Surge Overnight Treatment]]></g:title>
<g:description><![CDATA[The Moisture Surge Overnight Treatment aims to treat and intensively re-hydrate to give skin a healthier appearance.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/by-category/74-moisture-surge-overnight-treatment-647297414167-5060762540430.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/708-large_default/moisture-surge-overnight-treatment-647297414167.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/573-large_default/moisture-surge-overnight-treatment-647297414167.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/574-large_default/moisture-surge-overnight-treatment-647297414167.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/924-large_default/moisture-surge-overnight-treatment-647297414167.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1038-large_default/moisture-surge-overnight-treatment-647297414167.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1895-large_default/moisture-surge-overnight-treatment-647297414167.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:sale_price>63.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540430]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>75</g:id>
<g:title><![CDATA[Pro-Radiance Brightening Eye Final Touch]]></g:title>
<g:description><![CDATA[Part of the Avant Age Radiance range, this eye cream helps illuminate your eye area.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/eye-care/75-pro-radiance-brightening-eye-final-touch-647297414082-5060762540218.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/709-large_default/pro-radiance-brightening-eye-final-touch-647297414082.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/575-large_default/pro-radiance-brightening-eye-final-touch-647297414082.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/576-large_default/pro-radiance-brightening-eye-final-touch-647297414082.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/925-large_default/pro-radiance-brightening-eye-final-touch-647297414082.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1088-large_default/pro-radiance-brightening-eye-final-touch-647297414082.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1906-large_default/pro-radiance-brightening-eye-final-touch-647297414082.jpg]]></g:additional_image_link>
<g:price>97.00 GBP</g:price>
<g:sale_price>72.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Eye Care]]></g:product_type>
<g:gtin><![CDATA[5060762540218]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>76</g:id>
<g:title><![CDATA[Hyaluronic Acid Molecular Boost Eye Cream]]></g:title>
<g:description><![CDATA[Part of the Avant Age Nutri-Revive range, this eye cream aims to intensely nourish the eye contour, reducing the signs of ageing.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/eye-care/76-hyaluronic-acid-molecular-boost-eye-cream-647297414099-5060762540102.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/710-large_default/hyaluronic-acid-molecular-boost-eye-cream-647297414099.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/577-large_default/hyaluronic-acid-molecular-boost-eye-cream-647297414099.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/578-large_default/hyaluronic-acid-molecular-boost-eye-cream-647297414099.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/930-large_default/hyaluronic-acid-molecular-boost-eye-cream-647297414099.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1089-large_default/hyaluronic-acid-molecular-boost-eye-cream-647297414099.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2091-large_default/hyaluronic-acid-molecular-boost-eye-cream-647297414099.jpg]]></g:additional_image_link>
<g:price>97.00 GBP</g:price>
<g:sale_price>72.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Eye Care]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540102]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>77</g:id>
<g:title><![CDATA[3-1 Hyaluron-Filler Collagen Eye Formula]]></g:title>
<g:description><![CDATA[Our 3-1 Hyaluron-Filler Collagen Eye Formula is part of the Avant Age Restore range. This revolutionary formula contains signature ingredients contributing towards evening out the signs of ageing while maintaining a mattified complexion.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/eye-care/77-3-1-hyaluron-filler-collagen-eye-formula-647297414105-5060762540447.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/711-large_default/3-1-hyaluron-filler-collagen-eye-formula-647297414105.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/579-large_default/3-1-hyaluron-filler-collagen-eye-formula-647297414105.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/580-large_default/3-1-hyaluron-filler-collagen-eye-formula-647297414105.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/931-large_default/3-1-hyaluron-filler-collagen-eye-formula-647297414105.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1090-large_default/3-1-hyaluron-filler-collagen-eye-formula-647297414105.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1904-large_default/3-1-hyaluron-filler-collagen-eye-formula-647297414105.jpg]]></g:additional_image_link>
<g:price>99.00 GBP</g:price>
<g:sale_price>74.25 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Eye Care]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540447]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>78</g:id>
<g:title><![CDATA[Anti-ageing Glycolic Firming Eye Contour]]></g:title>
<g:description><![CDATA[Our Anti-ageing Glycolic Firming Eye Contour cream is part of the Avant Age Defy+ range. This cream aims to defy signs of ageing including crows feet, fine lines and wrinkles but also helps reduce dark circles and eye bags.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/eye-care/78-anti-ageing-glycolic-firming-eye-contour-647297414112-5060762540300.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/712-large_default/anti-ageing-glycolic-firming-eye-contour-647297414112.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/581-large_default/anti-ageing-glycolic-firming-eye-contour-647297414112.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/582-large_default/anti-ageing-glycolic-firming-eye-contour-647297414112.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/929-large_default/anti-ageing-glycolic-firming-eye-contour-647297414112.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1091-large_default/anti-ageing-glycolic-firming-eye-contour-647297414112.jpg]]></g:additional_image_link>
<g:price>99.00 GBP</g:price>
<g:sale_price>74.25 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Eye Care]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540300]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>79</g:id>
<g:title><![CDATA[Collagen Intense Radiance Activator Serum]]></g:title>
<g:description><![CDATA[Our Collagen Intense Radiance Activator Serum is infused with brightening and illuminating signature ingredients contributing towards visibly radiant and healthy skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/serums/79-collagen-intense-radiance-activator-serum-647297417106-5060762540225.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/714-large_default/collagen-intense-radiance-activator-serum-647297417106.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/586-large_default/collagen-intense-radiance-activator-serum-647297417106.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/585-large_default/collagen-intense-radiance-activator-serum-647297417106.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/933-large_default/collagen-intense-radiance-activator-serum-647297417106.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1092-large_default/collagen-intense-radiance-activator-serum-647297417106.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1903-large_default/collagen-intense-radiance-activator-serum-647297417106.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1970-large_default/collagen-intense-radiance-activator-serum-647297417106.jpg]]></g:additional_image_link>
<g:price>92.00 GBP</g:price>
<g:sale_price>69.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Serums]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540225]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.090 匁</g:shipping_weight>
</entry>

<entry>
<g:id>80</g:id>
<g:title><![CDATA[Eight Hour Retexturing  & Anti-Oxidising Hyaluronic Facial Serum]]></g:title>
<g:description><![CDATA[A revolutionary serum working to combat free radicals and protect against everyday pollution. It is specially formulated to redefine the skin's texture thanks to powerful hydrating ingredients.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/serums/80-8-hour-anti-oxidising-retexturing-hyaluronic-facial-serum-647297417113-5060762540126.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/715-large_default/8-hour-anti-oxidising-retexturing-hyaluronic-facial-serum-647297417113.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/597-large_default/8-hour-anti-oxidising-retexturing-hyaluronic-facial-serum-647297417113.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/598-large_default/8-hour-anti-oxidising-retexturing-hyaluronic-facial-serum-647297417113.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/934-large_default/8-hour-anti-oxidising-retexturing-hyaluronic-facial-serum-647297417113.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1093-large_default/8-hour-anti-oxidising-retexturing-hyaluronic-facial-serum-647297417113.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1894-large_default/8-hour-anti-oxidising-retexturing-hyaluronic-facial-serum-647297417113.jpg]]></g:additional_image_link>
<g:price>89.00 GBP</g:price>
<g:sale_price>66.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Serums]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540126]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.090 匁</g:shipping_weight>
</entry>

<entry>
<g:id>81</g:id>
<g:title><![CDATA[Hi-Retinol Restoring and Lifting Serum]]></g:title>
<g:description><![CDATA[Infused with powerful restorative and regenerative ingredients for flawless skin, this serum is ideal for skin that is oily, overworked, or weakened.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/serums/81-hi-retinol-restoring-and-lifting-serum-647297417120-5060762540454.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/716-large_default/hi-retinol-restoring-and-lifting-serum-647297417120.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/587-large_default/hi-retinol-restoring-and-lifting-serum-647297417120.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/588-large_default/hi-retinol-restoring-and-lifting-serum-647297417120.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/935-large_default/hi-retinol-restoring-and-lifting-serum-647297417120.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1095-large_default/hi-retinol-restoring-and-lifting-serum-647297417120.jpg]]></g:additional_image_link>
<g:price>87.00 GBP</g:price>
<g:sale_price>65.25 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Serums]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540454]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.090 匁</g:shipping_weight>
</entry>

<entry>
<g:id>82</g:id>
<g:title><![CDATA[R.N.A Radical Firmness Anti-Ageing Serum]]></g:title>
<g:description><![CDATA[Women & Home Beauty Awards 2021 - Best Firming Serum Winner, our R.N.A Radical Firmness Anti-Ageing Serum aims to firm your skin and smooth the appearance of wrinkles, keeping your skin youthful and glowing from every angle. Our revolutionary anti-ageing cream helps deliver unbelievable firmness to your skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/serums/82-rna-radical-firmness-anti-ageing-serum-647297417137-5060762540317.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/717-large_default/rna-radical-firmness-anti-ageing-serum-647297417137.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/590-large_default/rna-radical-firmness-anti-ageing-serum-647297417137.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/589-large_default/rna-radical-firmness-anti-ageing-serum-647297417137.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/936-large_default/rna-radical-firmness-anti-ageing-serum-647297417137.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1563-large_default/rna-radical-firmness-anti-ageing-serum-647297417137.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1096-large_default/rna-radical-firmness-anti-ageing-serum-647297417137.jpg]]></g:additional_image_link>
<g:price>119.00 GBP</g:price>
<g:sale_price>89.25 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Serums]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540317]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.090 匁</g:shipping_weight>
</entry>

<entry>
<g:id>83</g:id>
<g:title><![CDATA[Ageless Decolletage Retexturing Treatment]]></g:title>
<g:description><![CDATA[Our Ageless Decolletage Retexturing Treatment helps redefine your skin's texture by combatting enlarged pores and uneven skin tone.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/neck-chin-decolletage/83-ageless-decolletage-retexturing-treatment-647297417144-5060762540461.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/718-large_default/ageless-decolletage-retexturing-treatment-647297417144.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/592-large_default/ageless-decolletage-retexturing-treatment-647297417144.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/591-large_default/ageless-decolletage-retexturing-treatment-647297417144.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/938-large_default/ageless-decolletage-retexturing-treatment-647297417144.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1097-large_default/ageless-decolletage-retexturing-treatment-647297417144.jpg]]></g:additional_image_link>
<g:price>94.00 GBP</g:price>
<g:sale_price>70.50 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Neck, Chin & Decolletage]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540461]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.090 匁</g:shipping_weight>
</entry>

<entry>
<g:id>116</g:id>
<g:title><![CDATA[V-zone Age Reversing Chin emulsion]]></g:title>
<g:description><![CDATA[A Chin Emulsion that aims to radically combat the signs of ageing and helps reshapping and redefining the V-zone. ]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/neck-chin-decolletage/116-v-zone-age-reversing-chin-emulsion-5060762540324.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/719-large_default/v-zone-age-reversing-chin-emulsion.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/594-large_default/v-zone-age-reversing-chin-emulsion.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/593-large_default/v-zone-age-reversing-chin-emulsion.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/939-large_default/v-zone-age-reversing-chin-emulsion.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1098-large_default/v-zone-age-reversing-chin-emulsion.jpg]]></g:additional_image_link>
<g:price>89.00 GBP</g:price>
<g:sale_price>49.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Neck, Chin & Decolletage]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540324]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>120</g:id>
<g:title><![CDATA[Full Neck Tightening‭ ‬&‭ ‬Firming Treatment]]></g:title>
<g:description><![CDATA[Our Full Neck Tightening &amp; Firming Treatment is the perfect formula that works towards preventing dullness, combating ageing and rejuvenating skin around the neck area.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/neck-chin-decolletage/120-full-neck-tightening-firming-treatment-647297417168-5060762540331.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/720-large_default/full-neck-tightening-firming-treatment-647297417168.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/595-large_default/full-neck-tightening-firming-treatment-647297417168.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/596-large_default/full-neck-tightening-firming-treatment-647297417168.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/940-large_default/full-neck-tightening-firming-treatment-647297417168.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1099-large_default/full-neck-tightening-firming-treatment-647297417168.jpg]]></g:additional_image_link>
<g:price>89.00 GBP</g:price>
<g:sale_price>66.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Neck, Chin & Decolletage]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540331]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>121</g:id>
<g:title><![CDATA[Advanced Bio Ultra-Fine Texture Day Moisturiser]]></g:title>
<g:description><![CDATA[The Advanced Bio Ultra-fine Texture Day Moisturiser is enhanced with soothing and moisturising agents aiming to foster a mattified complexion.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/moisturisers-mists/121-advanced-bio-ultra-fine-texture-day-moisturiser-647732736151-5060762540027.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/697-large_default/advanced-bio-ultra-fine-texture-day-moisturiser-647732736151.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/551-large_default/advanced-bio-ultra-fine-texture-day-moisturiser-647732736151.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/552-large_default/advanced-bio-ultra-fine-texture-day-moisturiser-647732736151.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/912-large_default/advanced-bio-ultra-fine-texture-day-moisturiser-647732736151.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1081-large_default/advanced-bio-ultra-fine-texture-day-moisturiser-647732736151.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1908-large_default/advanced-bio-ultra-fine-texture-day-moisturiser-647732736151.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:sale_price>63.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Moisturisers & Mists]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540027]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.090 匁</g:shipping_weight>
</entry>

<entry>
<g:id>122</g:id>
<g:title><![CDATA[Gentle Rose Beautifying Face Exfoliant]]></g:title>
<g:description><![CDATA[The Rose Exfoliant is enriched with groundbreaking ingredients to help skin appear smoother and softer. The ingredients within are designed to penetrate the skin 20% more effectively.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/masks-exfoliators/122-gentle-rose-beautifying-face-exfoliant-655043795453-5060762540119.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/723-large_default/gentle-rose-beautifying-face-exfoliant-655043795453.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/604-large_default/gentle-rose-beautifying-face-exfoliant-655043795453.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/605-large_default/gentle-rose-beautifying-face-exfoliant-655043795453.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/943-large_default/gentle-rose-beautifying-face-exfoliant-655043795453.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1029-large_default/gentle-rose-beautifying-face-exfoliant-655043795453.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1899-large_default/gentle-rose-beautifying-face-exfoliant-655043795453.jpg]]></g:additional_image_link>
<g:price>92.00 GBP</g:price>
<g:sale_price>69.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Masks & Exfoliators]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540119]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>123</g:id>
<g:title><![CDATA[Hand Nail & Cuticle Anti-Ageing Cream]]></g:title>
<g:description><![CDATA[A unique hand cream aiming to effectively restore moisture and PH levels to the skin whilst also acting as an effective anti-ageing treatment.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hand-body/123-hand-nail-cuticle-anti-ageing-cream-5060762540140.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/727-large_default/hand-nail-cuticle-anti-ageing-cream.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/612-large_default/hand-nail-cuticle-anti-ageing-cream.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/613-large_default/hand-nail-cuticle-anti-ageing-cream.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/947-large_default/hand-nail-cuticle-anti-ageing-cream.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1101-large_default/hand-nail-cuticle-anti-ageing-cream.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:sale_price>63.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Hand & body]]></g:product_type>
<g:gtin><![CDATA[5060762540140]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>124</g:id>
<g:title><![CDATA[Perfecting Skin Renewal Foot Scrub]]></g:title>
<g:description><![CDATA[Infused with groundbreaking ingredients to help banish dry and callused skin on your feet. This scrub works to leave your feet smooth and luxuriously scented.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/masks-exfoliators/124-perfecting-skin-renewal-foot-scrub-5060762540669.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/725-large_default/perfecting-skin-renewal-foot-scrub.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/608-large_default/perfecting-skin-renewal-foot-scrub.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/609-large_default/perfecting-skin-renewal-foot-scrub.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/945-large_default/perfecting-skin-renewal-foot-scrub.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1032-large_default/perfecting-skin-renewal-foot-scrub.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:sale_price>49.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Masks & Exfoliators]]></g:product_type>
<g:gtin><![CDATA[5060762540669]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>125</g:id>
<g:title><![CDATA[Sumptuous Glycolic Acid Hand Balm]]></g:title>
<g:description><![CDATA[A Sumptuous Hand Balm aiming to restructure and regenerate hardened skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hand-body/125-sumptuous-glycolic-acid-hand-balm-655043795491-5060762540362.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/726-large_default/sumptuous-glycolic-acid-hand-balm-655043795491.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/610-large_default/sumptuous-glycolic-acid-hand-balm-655043795491.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/611-large_default/sumptuous-glycolic-acid-hand-balm-655043795491.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/946-large_default/sumptuous-glycolic-acid-hand-balm-655043795491.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1100-large_default/sumptuous-glycolic-acid-hand-balm-655043795491.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Hand & body]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540362]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>126</g:id>
<g:title><![CDATA[Instant Pro-Lux Hand Exfoliator]]></g:title>
<g:description><![CDATA[The Instant Pro-Lux Hand Exfoliator is enriched with a deeply soothing scent that aims to nourish your hands and lower arms, leaving them soft, smooth and youthful.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/masks-exfoliators/126-instant-pro-lux-hand-exfoliator-655043795460-5060762540652.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/724-large_default/instant-pro-lux-hand-exfoliator-655043795460.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/606-large_default/instant-pro-lux-hand-exfoliator-655043795460.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/607-large_default/instant-pro-lux-hand-exfoliator-655043795460.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/944-large_default/instant-pro-lux-hand-exfoliator-655043795460.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1031-large_default/instant-pro-lux-hand-exfoliator-655043795460.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:sale_price>63.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Masks & Exfoliators]]></g:product_type>
<g:gtin><![CDATA[5060762540652]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>127</g:id>
<g:title><![CDATA[Advanced Bio Restorative Superfood Facial Oil]]></g:title>
<g:description><![CDATA[A Superfood Facial Oil combining a replenishing elixir of oils and papaya extract that work to visibly restore your skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/facial-oils/127-advanced-bio-restorative-superfood-facial-oil-647732736168-5060762540034.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/698-large_default/advanced-bio-restorative-superfood-facial-oil-647732736168.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/554-large_default/advanced-bio-restorative-superfood-facial-oil-647732736168.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/553-large_default/advanced-bio-restorative-superfood-facial-oil-647732736168.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/914-large_default/advanced-bio-restorative-superfood-facial-oil-647732736168.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1039-large_default/advanced-bio-restorative-superfood-facial-oil-647732736168.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:sale_price>63.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Facial oils]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540034]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>128</g:id>
<g:title><![CDATA[Power E Jojoba Facial Drop Treatment]]></g:title>
<g:description><![CDATA[Power E Jojoba Facial Drop Treatment works to leave skin more resilient whilst profoundly hydrating it.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/facial-oils/128-power-e-jojoba-facial-drop-treatment-655043795521-5060762540485.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/729-large_default/power-e-jojoba-facial-drop-treatment-655043795521.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/617-large_default/power-e-jojoba-facial-drop-treatment-655043795521.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/616-large_default/power-e-jojoba-facial-drop-treatment-655043795521.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/949-large_default/power-e-jojoba-facial-drop-treatment-655043795521.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1056-large_default/power-e-jojoba-facial-drop-treatment-655043795521.jpg]]></g:additional_image_link>
<g:price>89.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Facial oils]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540485]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>129</g:id>
<g:title><![CDATA[Glycolic Acid Rejuvenating Face Exfoliator]]></g:title>
<g:description><![CDATA[Aims to gently exfoliate and retexture the skin. This rejuvenating exfoliator is designed to reveal refined, brightened and renewed skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/masks-exfoliators/129-glycolic-acid-rejuvenating-face-exfoliator-655043795446-5060762540348.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/722-large_default/glycolic-acid-rejuvenating-face-exfoliator-655043795446.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/602-large_default/glycolic-acid-rejuvenating-face-exfoliator-655043795446.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/603-large_default/glycolic-acid-rejuvenating-face-exfoliator-655043795446.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/942-large_default/glycolic-acid-rejuvenating-face-exfoliator-655043795446.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1028-large_default/glycolic-acid-rejuvenating-face-exfoliator-655043795446.jpg]]></g:additional_image_link>
<g:price>92.00 GBP</g:price>
<g:sale_price>69.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Masks & Exfoliators]]></g:product_type>
<g:gtin><![CDATA[5060762540348]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>130</g:id>
<g:title><![CDATA[Age Retexturing Glycolic Acid Mask-in-Cream]]></g:title>
<g:description><![CDATA[A decolletage mask-in-cream that combines signature ingredients including Shea Butter, Rice Bran, Glycolic Acid and Moringa Oil.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/moisturisers-mists/130-age-retexturing-glycolic-acid-mask-in-cream-655043795439-5060762540355.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/721-large_default/age-retexturing-glycolic-acid-mask-in-cream-655043795439.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/601-large_default/age-retexturing-glycolic-acid-mask-in-cream-655043795439.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/600-large_default/age-retexturing-glycolic-acid-mask-in-cream-655043795439.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/941-large_default/age-retexturing-glycolic-acid-mask-in-cream-655043795439.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1027-large_default/age-retexturing-glycolic-acid-mask-in-cream-655043795439.jpg]]></g:additional_image_link>
<g:price>99.00 GBP</g:price>
<g:sale_price>74.25 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Moisturisers & Mists]]></g:product_type>
<g:gtin><![CDATA[5060762540355]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>136</g:id>
<g:title><![CDATA[Hyaluronic Acid Replenishing Lip Serum]]></g:title>
<g:description><![CDATA[A Replenishing Lip Serum designed to simultaneously plump and nourish the lips.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/lip-care/136-hyaluronic-acid-replenishing-lip-serum-5060762540157.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/730-large_default/hyaluronic-acid-replenishing-lip-serum.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/618-large_default/hyaluronic-acid-replenishing-lip-serum.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/619-large_default/hyaluronic-acid-replenishing-lip-serum.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/950-large_default/hyaluronic-acid-replenishing-lip-serum.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1103-large_default/hyaluronic-acid-replenishing-lip-serum.jpg]]></g:additional_image_link>
<g:price>89.00 GBP</g:price>
<g:sale_price>66.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Lip Care]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540157]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.090 匁</g:shipping_weight>
</entry>

<entry>
<g:id>137</g:id>
<g:title><![CDATA[Anti-Ageing Collagen Lip Line Corrector]]></g:title>
<g:description><![CDATA[Harbouring revolutionary ingredients such as Collagen and Glycerin, the Anti-Ageing Collagen Lip Line Corrector is designed to address visible signs of ageing on the lips.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/lip-care/137-anti-ageing-collagen-lip-line-corrector-655043795552-5060762540379.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/731-large_default/anti-ageing-collagen-lip-line-corrector-655043795552.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/620-large_default/anti-ageing-collagen-lip-line-corrector-655043795552.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/621-large_default/anti-ageing-collagen-lip-line-corrector-655043795552.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/951-large_default/anti-ageing-collagen-lip-line-corrector-655043795552.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1059-large_default/anti-ageing-collagen-lip-line-corrector-655043795552.jpg]]></g:additional_image_link>
<g:price>89.00 GBP</g:price>
<g:sale_price>66.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Lip Care]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540379]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.060 匁</g:shipping_weight>
</entry>

<entry>
<g:id>138</g:id>
<g:title><![CDATA[R.N.A. Radical Anti-Ageing Eye Lift Cream]]></g:title>
<g:description><![CDATA[Designed to defy ageing in the most radical way, this eye cream aims to even out fine lines, wrinkles and crows feet to foster a firmer and healthier eye area.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/eye-care/138-rna-radical-anti-ageing-eye-lift-cream-655043795569-5060762540386.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/732-large_default/rna-radical-anti-ageing-eye-lift-cream-655043795569.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/622-large_default/rna-radical-anti-ageing-eye-lift-cream-655043795569.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/623-large_default/rna-radical-anti-ageing-eye-lift-cream-655043795569.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/952-large_default/rna-radical-anti-ageing-eye-lift-cream-655043795569.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1104-large_default/rna-radical-anti-ageing-eye-lift-cream-655043795569.jpg]]></g:additional_image_link>
<g:price>89.00 GBP</g:price>
<g:sale_price>66.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Eye Care]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540386]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.060 匁</g:shipping_weight>
</entry>

<entry>
<g:id>140</g:id>
<g:title><![CDATA[Age Prestige Antioxidising & Detoxifying Rose Serum]]></g:title>
<g:description><![CDATA[Part of the Age Nutri-Revive collection at Avant Skincare, this serum has been specifically designed to remove impurities, boosting radiance while aiming to smooth fine lines and wrinkles.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/serums/140-age-prestige-antioxidising-detoxifying-rose-serum-655043795903-5060762540133.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/734-large_default/age-prestige-antioxidising-detoxifying-rose-serum-655043795903.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/627-large_default/age-prestige-antioxidising-detoxifying-rose-serum-655043795903.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/626-large_default/age-prestige-antioxidising-detoxifying-rose-serum-655043795903.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/954-large_default/age-prestige-antioxidising-detoxifying-rose-serum-655043795903.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1105-large_default/age-prestige-antioxidising-detoxifying-rose-serum-655043795903.jpg]]></g:additional_image_link>
<g:price>109.00 GBP</g:price>
<g:sale_price>81.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Serums]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540133]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>141</g:id>
<g:title><![CDATA[Instant Radiance and Anti-Ageing Gel Charmer Gold & Bronze]]></g:title>
<g:description><![CDATA[With its innovative gel texture, rich in micro gold and bronze pearl particles, this formula aims to absorb rapidly into the skin, working to leave skin soft and delicately scented. Non-greasy and non-staining, it is designed for highlighting tanned skin and can be used all year round to help give a healthy and lightly coloured glow.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/moisturisers-mists/141-instant-radiance-and-anti-ageing-gel-charmer-gold-bronze-655043795897-5060762540232.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/878-large_default/instant-radiance-and-anti-ageing-gel-charmer-gold-bronze-655043795897.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/955-large_default/instant-radiance-and-anti-ageing-gel-charmer-gold-bronze-655043795897.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/879-large_default/instant-radiance-and-anti-ageing-gel-charmer-gold-bronze-655043795897.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1045-large_default/instant-radiance-and-anti-ageing-gel-charmer-gold-bronze-655043795897.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:sale_price>63.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Moisturisers & Mists]]></g:product_type>
<g:gtin><![CDATA[5060762540232]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.090 匁</g:shipping_weight>
</entry>

<entry>
<g:id>143</g:id>
<g:title><![CDATA[R.N.A Radical Anti-Ageing & Lifting Duo Moisturiser]]></g:title>
<g:description><![CDATA[One of the most radical ways to combat ageing, this day and night formula works to reduce the appearance of fine lines and wrinkles for a youthful complexion.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/moisturisers-mists/143-rna-radical-anti-ageing-lifting-duo-moisturiser-655043795576-5060762540393.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/733-large_default/rna-radical-anti-ageing-lifting-duo-moisturiser-655043795576.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/624-large_default/rna-radical-anti-ageing-lifting-duo-moisturiser-655043795576.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/625-large_default/rna-radical-anti-ageing-lifting-duo-moisturiser-655043795576.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/953-large_default/rna-radical-anti-ageing-lifting-duo-moisturiser-655043795576.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1058-large_default/rna-radical-anti-ageing-lifting-duo-moisturiser-655043795576.jpg]]></g:additional_image_link>
<g:price>99.00 GBP</g:price>
<g:sale_price>74.25 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Moisturisers & Mists]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540393]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>144</g:id>
<g:title><![CDATA[Rose Radiance & Anti-ageing Hyaluronic Eye Serum]]></g:title>
<g:description><![CDATA[The Rose Radiance &amp; Anti-ageing Hyaluronic Eye Serum is an ultra gentle eye serum, designed with the delicate skin and sensitivity of the eyes in mind: the first area to show signs of ageing. It aims to provide an uplifting radiance and refreshing sensation whilst evening out micro-reliefs, wrinkles, puffiness and eye bags.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/eye-care/144-rose-radiance-anti-ageing-hyaluronic-eye-serum-655043795880-5060762540188.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/736-large_default/rose-radiance-anti-ageing-hyaluronic-eye-serum-655043795880.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/631-large_default/rose-radiance-anti-ageing-hyaluronic-eye-serum-655043795880.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/630-large_default/rose-radiance-anti-ageing-hyaluronic-eye-serum-655043795880.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/956-large_default/rose-radiance-anti-ageing-hyaluronic-eye-serum-655043795880.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1106-large_default/rose-radiance-anti-ageing-hyaluronic-eye-serum-655043795880.jpg]]></g:additional_image_link>
<g:price>102.00 GBP</g:price>
<g:sale_price>76.50 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Eye Care]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540188]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>145</g:id>
<g:title><![CDATA[Avant Quintessence]]></g:title>
<g:description><![CDATA[A fragrance to share™ A semi-oriental floral amber fragrance, sweet, warm, with an elegant powdery base harmonised with flowers and a spicy note, this ‘fragrance-to-share™’ is the very essence of sophistication: A modern classic.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/perfumes/145-quintessence-655043795873-5060762540270.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1930-large_default/quintessence-655043795873.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1929-large_default/quintessence-655043795873.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1046-large_default/quintessence-655043795873.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2048-large_default/quintessence-655043795873.jpg]]></g:additional_image_link>
<g:price>119.00 GBP</g:price>
<g:sale_price>89.25 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Perfumes]]></g:product_type>
<g:gtin><![CDATA[5060762540270]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>146</g:id>
<g:title><![CDATA[Rose & Squalane Revitalising DUO Moisturiser]]></g:title>
<g:description><![CDATA[By infusing 3 precious ingredients known for their revitalising and repairing properties on skin – Rose, Squalane and Tocopherol - into a silky cream, we have created a day and night cream that aims to intensely revitalise skin, replenish moisture and revive radiance.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/moisturisers-mists/146-rose-squalane-revitalising-duo-moisturiser-669203935245-5060762540492.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/738-large_default/rose-squalane-revitalising-duo-moisturiser-669203935245.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/635-large_default/rose-squalane-revitalising-duo-moisturiser-669203935245.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/634-large_default/rose-squalane-revitalising-duo-moisturiser-669203935245.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/957-large_default/rose-squalane-revitalising-duo-moisturiser-669203935245.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1047-large_default/rose-squalane-revitalising-duo-moisturiser-669203935245.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:sale_price>63.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Moisturisers & Mists]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540492]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.090 匁</g:shipping_weight>
</entry>

<entry>
<g:id>147</g:id>
<g:title><![CDATA[Ceramides Soothing & Protective Day Cream SPF 20]]></g:title>
<g:description><![CDATA[A potent, free radical-fighting blend, this moisturiser aims to neutralise the effects of everyday exposure to environmental aggressors – preventing irreparable DNA damage, to maintain your skin’s youthful bloom.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/moisturisers-mists/147-ceramide-soothing-protective-day-cream-spf-20-669203935252-5060762540515.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/739-large_default/ceramide-soothing-protective-day-cream-spf-20-669203935252.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/636-large_default/ceramide-soothing-protective-day-cream-spf-20-669203935252.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/637-large_default/ceramide-soothing-protective-day-cream-spf-20-669203935252.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/958-large_default/ceramide-soothing-protective-day-cream-spf-20-669203935252.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1050-large_default/ceramide-soothing-protective-day-cream-spf-20-669203935252.jpg]]></g:additional_image_link>
<g:price>89.00 GBP</g:price>
<g:sale_price>66.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Moisturisers & Mists]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540515]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>148</g:id>
<g:title><![CDATA[Intensive Redensifying Glycolic Acid Day Moisturiser]]></g:title>
<g:description><![CDATA[The ideal remedy against time, the Intensive Redensifying Glycolic Acid Day Moisturiser aims to offer a complete response to signs of skin slacking.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/moisturisers-mists/148-intensive-redensifying-glycolic-acid-day-moisturiser-669203935269-5060762540164.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/740-large_default/intensive-redensifying-glycolic-acid-day-moisturiser-669203935269.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/638-large_default/intensive-redensifying-glycolic-acid-day-moisturiser-669203935269.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/639-large_default/intensive-redensifying-glycolic-acid-day-moisturiser-669203935269.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/959-large_default/intensive-redensifying-glycolic-acid-day-moisturiser-669203935269.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1049-large_default/intensive-redensifying-glycolic-acid-day-moisturiser-669203935269.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1905-large_default/intensive-redensifying-glycolic-acid-day-moisturiser-669203935269.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:sale_price>63.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Moisturisers & Mists]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540164]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>149</g:id>
<g:title><![CDATA[R.N.A Radical Anti-Ageing & Retexturing Face and Eye Cream]]></g:title>
<g:description><![CDATA[This lightweight, R.N.A Radical Anti-Ageing &amp; Retexturing Face and Eye Cream is blended with revolutionary ingredients including Glycolic Acid, Trelahose and Jojoba Oil for dramatic exfoliation aiming to reveal newer, fresher skin and improve the appearance of discolorations and pores.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/moisturisers-mists/149-rna-radical-anti-ageing-retexturing-face-and-eye-cream-669203935276-5060762540409.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/741-large_default/rna-radical-anti-ageing-retexturing-face-and-eye-cream-669203935276.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/641-large_default/rna-radical-anti-ageing-retexturing-face-and-eye-cream-669203935276.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/640-large_default/rna-radical-anti-ageing-retexturing-face-and-eye-cream-669203935276.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/960-large_default/rna-radical-anti-ageing-retexturing-face-and-eye-cream-669203935276.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1048-large_default/rna-radical-anti-ageing-retexturing-face-and-eye-cream-669203935276.jpg]]></g:additional_image_link>
<g:price>89.00 GBP</g:price>
<g:sale_price>66.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Moisturisers & Mists]]></g:product_type>
<g:gtin><![CDATA[5060762540409]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>150</g:id>
<g:title><![CDATA[Glycolic Acid Vivifying & Firming Body Treatment]]></g:title>
<g:description><![CDATA[A Vivifying &amp; Firming Treatment enriched with Glycerin, Glycolic Acid and Mica aiming to help tone and detoxify skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hand-body/150-glycolic-acid-vivifying-firming-body-treatment-669203935283-5060762540171.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/742-large_default/glycolic-acid-vivifying-firming-body-treatment-669203935283.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/642-large_default/glycolic-acid-vivifying-firming-body-treatment-669203935283.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/643-large_default/glycolic-acid-vivifying-firming-body-treatment-669203935283.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/961-large_default/glycolic-acid-vivifying-firming-body-treatment-669203935283.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1051-large_default/glycolic-acid-vivifying-firming-body-treatment-669203935283.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1911-large_default/glycolic-acid-vivifying-firming-body-treatment-669203935283.jpg]]></g:additional_image_link>
<g:price>89.00 GBP</g:price>
<g:sale_price>66.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Hand & body]]></g:product_type>
<g:gtin><![CDATA[5060762540171]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>151</g:id>
<g:title><![CDATA[R.N.A Radical Anti-Ageing & Tightening Body Cream]]></g:title>
<g:description><![CDATA[This revolutionary R.N.A Radical Anti-Ageing & Tightening Body Cream is designed to reverse the visual effects of ageing on skin, namely sagging, wrinkling, and lack of tone. Massaging this rich body cream into targeted areas will noticeably help firm and tighten skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hand-body/151-rna-radical-anti-ageing-tightening-body-cream-669203935290-5060762540416.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/743-large_default/rna-radical-anti-ageing-tightening-body-cream-669203935290.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/644-large_default/rna-radical-anti-ageing-tightening-body-cream-669203935290.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/645-large_default/rna-radical-anti-ageing-tightening-body-cream-669203935290.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/962-large_default/rna-radical-anti-ageing-tightening-body-cream-669203935290.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1052-large_default/rna-radical-anti-ageing-tightening-body-cream-669203935290.jpg]]></g:additional_image_link>
<g:price>89.00 GBP</g:price>
<g:sale_price>66.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Hand & body]]></g:product_type>
<g:gtin><![CDATA[5060762540416]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>153</g:id>
<g:title><![CDATA[Hyaluronic Acid Age Fix Toning Night Concentrate Mist]]></g:title>
<g:description><![CDATA[Harbouring powerful hydrating and anti-ageing properties, the Hyaluronic Acid Age Fix Toning Night Concentrate Mist has been formulated to tone and soften skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/moisturisers-mists/153-hyaluronic-acid-age-fix-toning-night-concentrate-mist-669203935313-5060762540423.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/745-large_default/hyaluronic-acid-age-fix-toning-night-concentrate-mist-669203935313.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/649-large_default/hyaluronic-acid-age-fix-toning-night-concentrate-mist-669203935313.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/648-large_default/hyaluronic-acid-age-fix-toning-night-concentrate-mist-669203935313.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1054-large_default/hyaluronic-acid-age-fix-toning-night-concentrate-mist-669203935313.jpg]]></g:additional_image_link>
<g:price>82.00 GBP</g:price>
<g:sale_price>61.50 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Moisturisers & Mists]]></g:product_type>
<g:gtin><![CDATA[5060762540423]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.140 匁</g:shipping_weight>
</entry>

<entry>
<g:id>154</g:id>
<g:title><![CDATA[PH Balancing & Brightening Rose Infusion Day Mist]]></g:title>
<g:description><![CDATA[The essential moisturising mist for women and men on the go. PH Balancing & Brightening Rose Infusion Day Mist is a concentrated spray that aims to hydrate, protect and energise skin at any time of day. Its fine texture envelops the face in a halo of freshness with a fruity fragrance. Ideal to help set makeup.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/moisturisers-mists/154-ph-balancing-brightening-rose-infusion-day-mist-669203935320-5060762540249.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/746-large_default/ph-balancing-brightening-rose-infusion-day-mist-669203935320.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/650-large_default/ph-balancing-brightening-rose-infusion-day-mist-669203935320.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/651-large_default/ph-balancing-brightening-rose-infusion-day-mist-669203935320.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1055-large_default/ph-balancing-brightening-rose-infusion-day-mist-669203935320.jpg]]></g:additional_image_link>
<g:price>79.00 GBP</g:price>
<g:sale_price>49.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Moisturisers & Mists]]></g:product_type>
<g:gtin><![CDATA[5060762540249]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>155</g:id>
<g:title><![CDATA[Profusion Algae Regenerative & Tightening Anti-Pollution Night Treatment]]></g:title>
<g:description><![CDATA[A moisturiser formulated for all skin types temporarily irritated by aggressors, including pollution, climatic conditions, stress or non-invasive microdermabrasion. Overnight, it aims to re-balance the complexion and nurture it back to a more resilient, less reactive state.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/night-creams/155-profusion-algae-regenerative-tightening-anti-pollution-night-treatment-669203935191-5060762540522.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/493-large_default/profusion-algae-regenerative-tightening-anti-pollution-night-treatment-669203935191.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/652-large_default/profusion-algae-regenerative-tightening-anti-pollution-night-treatment-669203935191.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/653-large_default/profusion-algae-regenerative-tightening-anti-pollution-night-treatment-669203935191.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/964-large_default/profusion-algae-regenerative-tightening-anti-pollution-night-treatment-669203935191.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1107-large_default/profusion-algae-regenerative-tightening-anti-pollution-night-treatment-669203935191.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:sale_price>49.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Night creams]]></g:product_type>
<g:gtin><![CDATA[5060762540522]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>156</g:id>
<g:title><![CDATA[Profusion Algae Revitalising & Firming Anti-Pollution Day Cream]]></g:title>
<g:description><![CDATA[An Anti-Pollution Day Cream that aims to enhance skin's power of protection for tailor-made daily defense. Adapting to the level of exposure, it aims to safeguard skin internally and externally from UV rays, pollution and free radicals. Harbouring powerful ingredients, it helps revitalise and increase skin firmness.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/moisturisers-mists/156-profusion-algae-revitalising-firming-anti-pollution-day-cream-669203935207-5060762540539.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/494-large_default/profusion-algae-revitalising-firming-anti-pollution-day-cream-669203935207.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/654-large_default/profusion-algae-revitalising-firming-anti-pollution-day-cream-669203935207.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/655-large_default/profusion-algae-revitalising-firming-anti-pollution-day-cream-669203935207.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/965-large_default/profusion-algae-revitalising-firming-anti-pollution-day-cream-669203935207.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1108-large_default/profusion-algae-revitalising-firming-anti-pollution-day-cream-669203935207.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:sale_price>49.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Moisturisers & Mists]]></g:product_type>
<g:gtin><![CDATA[5060762540539]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>159</g:id>
<g:title><![CDATA[Damascan Rose Petals Revitalising Facial Serum]]></g:title>
<g:description><![CDATA[A beauty refiner enriched with precious petals and extract of Orchid. It is the ideal product to work to renew the skin and help fight the signs of ageing and stress. Its formula contains a new generation of fermented active agents that act with a booster effect to enhance the serum's results.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/serums/159-damascan-rose-petals-revitalising-facial-serum-669203935214-5060762540546.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1681-large_default/damascan-rose-petals-revitalising-facial-serum-669203935214.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1687-large_default/damascan-rose-petals-revitalising-facial-serum-669203935214.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1237-large_default/damascan-rose-petals-revitalising-facial-serum-669203935214.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1238-large_default/damascan-rose-petals-revitalising-facial-serum-669203935214.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/966-large_default/damascan-rose-petals-revitalising-facial-serum-669203935214.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1043-large_default/damascan-rose-petals-revitalising-facial-serum-669203935214.jpg]]></g:additional_image_link>
<g:price>114.00 GBP</g:price>
<g:sale_price>85.50 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Serums]]></g:product_type>
<g:gtin><![CDATA[5060762540546]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>160</g:id>
<g:title><![CDATA[Damascan Rose Petals Antioxidising & Retexturing Treatment Mask]]></g:title>
<g:description><![CDATA[A soft jelly rich in precious petals that aims to moisturise, reduce the signs of ageing and brighten the face, giving a fresh appearance to the skin. A formula that helps strengthen and tone skin, bringing to light its younger and radiant appearance.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/masks-exfoliators/160-damascan-rose-petals-antioxidising-retexturing-treatment-mask-669203935221-5060762540553.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/502-large_default/damascan-rose-petals-antioxidising-retexturing-treatment-mask-669203935221.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/658-large_default/damascan-rose-petals-antioxidising-retexturing-treatment-mask-669203935221.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/659-large_default/damascan-rose-petals-antioxidising-retexturing-treatment-mask-669203935221.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/967-large_default/damascan-rose-petals-antioxidising-retexturing-treatment-mask-669203935221.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1044-large_default/damascan-rose-petals-antioxidising-retexturing-treatment-mask-669203935221.jpg]]></g:additional_image_link>
<g:price>109.00 GBP</g:price>
<g:sale_price>81.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Masks & Exfoliators]]></g:product_type>
<g:gtin><![CDATA[5060762540553]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>161</g:id>
<g:title><![CDATA[Satin-Soft Imperial Polyglutamic Acid DUO Moisturiser]]></g:title>
<g:description><![CDATA[A dreamy duo for nourished, hydrated and protected skin. This revolutionary cream aims to deliver Polyglutamic Acid's signature softness, achieving a youthful glow in next to no time.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/night-creams/161-satin-soft-imperial-polyglutamic-acid-duo-moisturiser-669203925635-5060762540560.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/503-large_default/satin-soft-imperial-polyglutamic-acid-duo-moisturiser-669203925635.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/662-large_default/satin-soft-imperial-polyglutamic-acid-duo-moisturiser-669203925635.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/663-large_default/satin-soft-imperial-polyglutamic-acid-duo-moisturiser-669203925635.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/970-large_default/satin-soft-imperial-polyglutamic-acid-duo-moisturiser-669203925635.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1057-large_default/satin-soft-imperial-polyglutamic-acid-duo-moisturiser-669203925635.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:sale_price>63.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Night creams]]></g:product_type>
<g:gtin><![CDATA[5060762540560]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.090 匁</g:shipping_weight>
</entry>

<entry>
<g:id>162</g:id>
<g:title><![CDATA[Pro Perfecting Collagen Touche Éclat Primer]]></g:title>
<g:description><![CDATA[Avant Pro Perfecting Primer is a silky, lightweight, universal primer that works to instantly perfect the look of skin and is blended with powerful ingredients to create the ideal canvas to enhance the look and wear of your makeup.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/primers/162-pro-perfecting-collagen-touche-eclat-primer-669203935238-5060762540508.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1893-large_default/pro-perfecting-collagen-touche-eclat-primer-669203935238.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/504-large_default/pro-perfecting-collagen-touche-eclat-primer-669203935238.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/660-large_default/pro-perfecting-collagen-touche-eclat-primer-669203935238.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/661-large_default/pro-perfecting-collagen-touche-eclat-primer-669203935238.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/968-large_default/pro-perfecting-collagen-touche-eclat-primer-669203935238.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1109-large_default/pro-perfecting-collagen-touche-eclat-primer-669203935238.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:sale_price>63.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Primers]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540508]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>164</g:id>
<g:title><![CDATA[Proactive Salicylic Acne & Imperfections Repair Treatment]]></g:title>
<g:description><![CDATA[Clinically proven to help reduce acne and breakouts whilst improving skin's appearance and targeting acne bacteria, the Proactive Salicylic Acne &amp; Imperfections Repair Treatment is enriched with Salycilic Acid, Zincidone and Walnut extract. Lightweight and soothing, this moisturiser helps eradicate bacteria, oiliness and blackheads, leaving skin smooth, calmed and glowing.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/by-category/164-proactive-salicylic-acne-imperfections-repair-treatment-5060762540676-5060762540676.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/528-large_default/proactive-salicylic-acne-imperfections-repair-treatment-5060762540676.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/666-large_default/proactive-salicylic-acne-imperfections-repair-treatment-5060762540676.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/667-large_default/proactive-salicylic-acne-imperfections-repair-treatment-5060762540676.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/972-large_default/proactive-salicylic-acne-imperfections-repair-treatment-5060762540676.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1060-large_default/proactive-salicylic-acne-imperfections-repair-treatment-5060762540676.jpg]]></g:additional_image_link>
<g:price>89.00 GBP</g:price>
<g:sale_price>66.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540676]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.090 匁</g:shipping_weight>
</entry>

<entry>
<g:id>165</g:id>
<g:title><![CDATA[Dynamic Salicylic Acne & Blemish Battling Cleanser]]></g:title>
<g:description><![CDATA[A gentle multi-purpose skincare solution that aims to remove makeup, impurities and excess oil without disturbing the skin's natural moisture balance.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/toners-cleansers/165-dynamic-salicylic-acne-blemish-battling-cleanser-669203926243-5060762540683.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/529-large_default/dynamic-salicylic-acne-blemish-battling-cleanser-669203926243.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/668-large_default/dynamic-salicylic-acne-blemish-battling-cleanser-669203926243.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/669-large_default/dynamic-salicylic-acne-blemish-battling-cleanser-669203926243.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/973-large_default/dynamic-salicylic-acne-blemish-battling-cleanser-669203926243.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1061-large_default/dynamic-salicylic-acne-blemish-battling-cleanser-669203926243.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:sale_price>63.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Toners & Cleansers]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540683]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>169</g:id>
<g:title><![CDATA[Sublime Peony & White Caviar Illuminating Pearls Serum]]></g:title>
<g:description><![CDATA[Infused with liquid light, this rare burst of White Caviar Pearls helps your skin regain luminosity and evenness whilst encouraging dark spots, redness and grey complexion to fade away. Bathed in a silky jellified serum, the White Caviar Pearls encapsulate the rarest caviar extract infused with Peony to work to return radiance to the skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/serums/169-sublime-peony-white-caviar-illuminating-pearls-serum-669203926106-5060762540263.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/534-large_default/sublime-peony-white-caviar-illuminating-pearls-serum-669203926106.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/664-large_default/sublime-peony-white-caviar-illuminating-pearls-serum-669203926106.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/665-large_default/sublime-peony-white-caviar-illuminating-pearls-serum-669203926106.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/971-large_default/sublime-peony-white-caviar-illuminating-pearls-serum-669203926106.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1110-large_default/sublime-peony-white-caviar-illuminating-pearls-serum-669203926106.jpg]]></g:additional_image_link>
<g:price>129.00 GBP</g:price>
<g:sale_price>96.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Serums]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540263]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.090 匁</g:shipping_weight>
</entry>

<entry>
<g:id>170</g:id>
<g:title><![CDATA[Infinite Vivifying & Replenishing Sustainable Arabica Coffee Scrub]]></g:title>
<g:description><![CDATA[This deeply invigorating blend of Coffee and Caprylic Capric Triglycerides help cleanse dry areas, removing impurities to reveal a youthful glow.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/masks-exfoliators/170-infinite-vivifying-replenishing-sustainable-arabica-coffee-scrub-669203926397-669203926397.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/535-large_default/infinite-vivifying-replenishing-sustainable-arabica-coffee-scrub-669203926397.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/677-large_default/infinite-vivifying-replenishing-sustainable-arabica-coffee-scrub-669203926397.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/678-large_default/infinite-vivifying-replenishing-sustainable-arabica-coffee-scrub-669203926397.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/974-large_default/infinite-vivifying-replenishing-sustainable-arabica-coffee-scrub-669203926397.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1062-large_default/infinite-vivifying-replenishing-sustainable-arabica-coffee-scrub-669203926397.jpg]]></g:additional_image_link>
<g:price>82.00 GBP</g:price>
<g:sale_price>61.50 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Masks & Exfoliators]]></g:product_type>
<g:gtin><![CDATA[669203926397]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.090 匁</g:shipping_weight>
</entry>

<entry>
<g:id>171</g:id>
<g:title><![CDATA[2-1 Glutamic Skin Lightening & Dark Spot Reducer]]></g:title>
<g:description><![CDATA[A concentrated cutting edge dark spot treatment that aims to directly target stubborn and hard to remove spots that are trapped deep in the skin layers to reveal a perfectly even skin tone.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/moisturisers-mists/171-2-1-glutamic-skin-lightening-dark-spot-reducer-669203926403-5060762540584.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/536-large_default/2-1-glutamic-skin-lightening-dark-spot-reducer-669203926403.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/679-large_default/2-1-glutamic-skin-lightening-dark-spot-reducer-669203926403.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/680-large_default/2-1-glutamic-skin-lightening-dark-spot-reducer-669203926403.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/975-large_default/2-1-glutamic-skin-lightening-dark-spot-reducer-669203926403.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1114-large_default/2-1-glutamic-skin-lightening-dark-spot-reducer-669203926403.jpg]]></g:additional_image_link>
<g:price>87.00 GBP</g:price>
<g:sale_price>65.25 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Moisturisers & Mists]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540584]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>172</g:id>
<g:title><![CDATA[Intense Acne Battling & Purifying French Green Clay Mask]]></g:title>
<g:description><![CDATA[Reveal a renewed complexion and battle and prevent acne, with the Avant Intense Acne Battling & Purifying French Green Clay Mask. It aims to deeply nourish and uses a potent combination of Green Clay and Zincidone to help lift away impurities.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/masks-exfoliators/172-intense-acne-battling-purifying-french-green-clay-mask-669203926410-5060762540720.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/537-large_default/intense-acne-battling-purifying-french-green-clay-mask-669203926410.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/681-large_default/intense-acne-battling-purifying-french-green-clay-mask-669203926410.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/682-large_default/intense-acne-battling-purifying-french-green-clay-mask-669203926410.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/976-large_default/intense-acne-battling-purifying-french-green-clay-mask-669203926410.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1063-large_default/intense-acne-battling-purifying-french-green-clay-mask-669203926410.jpg]]></g:additional_image_link>
<g:price>77.00 GBP</g:price>
<g:sale_price>57.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Masks & Exfoliators]]></g:product_type>
<g:gtin><![CDATA[5060762540720]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.090 匁</g:shipping_weight>
</entry>

<entry>
<g:id>173</g:id>
<g:title><![CDATA[Harmonious Rose Quartz Revitalising & Firming Mask]]></g:title>
<g:description><![CDATA[This luxurious mask aims to purify pores and absorb impurities and excess sebum, leaving you with balanced, healthy-looking skin. It helps tighten and clear congested pores and contributes to brightening tone as it draws out impurities.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/masks-exfoliators/173-harmonious-rose-quartz-revitalising-firming-mask-669203926427-5060762540591.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/538-large_default/harmonious-rose-quartz-revitalising-firming-mask-669203926427.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/683-large_default/harmonious-rose-quartz-revitalising-firming-mask-669203926427.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/684-large_default/harmonious-rose-quartz-revitalising-firming-mask-669203926427.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/977-large_default/harmonious-rose-quartz-revitalising-firming-mask-669203926427.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1115-large_default/harmonious-rose-quartz-revitalising-firming-mask-669203926427.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1901-large_default/harmonious-rose-quartz-revitalising-firming-mask-669203926427.jpg]]></g:additional_image_link>
<g:price>79.00 GBP</g:price>
<g:sale_price>59.25 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Masks & Exfoliators]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540591]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>174</g:id>
<g:title><![CDATA[Velvet Perfecting Rose Sugar Lip Scrub]]></g:title>
<g:description><![CDATA[The iconic scrub at Avant has been reinterpreted in a lip scrub. Gently exfoliating dry lips to leave them delicately smoothed and beautified, it's no wonder it has become the go-to product for our customers.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/lip-care/174-velvet-perfecting-rose-sugar-lip-scrub-669203926434-5060762540607.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/539-large_default/velvet-perfecting-rose-sugar-lip-scrub-669203926434.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/685-large_default/velvet-perfecting-rose-sugar-lip-scrub-669203926434.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/686-large_default/velvet-perfecting-rose-sugar-lip-scrub-669203926434.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/978-large_default/velvet-perfecting-rose-sugar-lip-scrub-669203926434.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1064-large_default/velvet-perfecting-rose-sugar-lip-scrub-669203926434.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1907-large_default/velvet-perfecting-rose-sugar-lip-scrub-669203926434.jpg]]></g:additional_image_link>
<g:price>57.00 GBP</g:price>
<g:sale_price>39.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Lip Care]]></g:product_type>
<g:gtin><![CDATA[5060762540607]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.110 匁</g:shipping_weight>
</entry>

<entry>
<g:id>175</g:id>
<g:title><![CDATA[Bi-Phase Hyaluronic Acid Rejuvenating Micellar Water]]></g:title>
<g:description><![CDATA[Infused with replenishing ingredients, Bi-Phase Hyaluronic Acid Rejuvenating Micellar Water works in two phases. First, the oil phase, aiming to instantly dissolve resilient and waterproof make-up. Then, the micellar water phase, working to capture impurities, whilst dissolving make-up from the face, eyes and lips in one gentle action, without rubbing or rinsing.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/toners-cleansers/175-bi-phase-hyaluronic-acid-rejuvenating-micellar-water-669203926441-5060762540614.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/540-large_default/bi-phase-hyaluronic-acid-rejuvenating-micellar-water-669203926441.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/687-large_default/bi-phase-hyaluronic-acid-rejuvenating-micellar-water-669203926441.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/688-large_default/bi-phase-hyaluronic-acid-rejuvenating-micellar-water-669203926441.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/979-large_default/bi-phase-hyaluronic-acid-rejuvenating-micellar-water-669203926441.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1065-large_default/bi-phase-hyaluronic-acid-rejuvenating-micellar-water-669203926441.jpg]]></g:additional_image_link>
<g:price>72.00 GBP</g:price>
<g:sale_price>54.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Toners & Cleansers]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540614]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>176</g:id>
<g:title><![CDATA[Proactive Mandelic Acid Restoring & Anti-Pollution Toner]]></g:title>
<g:description><![CDATA[The Avant Proactive Mandelic Acid Restoring & Anti-Pollution Toner aims to gently exfoliate, refresh and stimulate dry and congested complexions.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/toners-cleansers/176-proactive-mandelic-acid-restoring-anti-pollution-toner-5060762540621-5060762540621.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/541-large_default/proactive-mandelic-acid-restoring-anti-pollution-toner-5060762540621.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/689-large_default/proactive-mandelic-acid-restoring-anti-pollution-toner-5060762540621.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/690-large_default/proactive-mandelic-acid-restoring-anti-pollution-toner-5060762540621.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/980-large_default/proactive-mandelic-acid-restoring-anti-pollution-toner-5060762540621.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1068-large_default/proactive-mandelic-acid-restoring-anti-pollution-toner-5060762540621.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2022-large_default/proactive-mandelic-acid-restoring-anti-pollution-toner-5060762540621.jpg]]></g:additional_image_link>
<g:price>77.00 GBP</g:price>
<g:sale_price>57.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Toners & Cleansers]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540621]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.140 匁</g:shipping_weight>
</entry>

<entry>
<g:id>178</g:id>
<g:title><![CDATA[Advanced Pack - Hydra-Bright Collagen Eye Restoring Pads - 5 pairs]]></g:title>
<g:description><![CDATA[Wake up to brighter eyes with this revolutionary eye set, saturated with a complex combination of active ingredients. It aims to smooth, intensely hydrate and minimise the appearance of fine lines, dark circles and puffiness.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/eye-care/178-advanced-pack-hydra-bright-collagen-eye-restoring-pads-669203926694-5060762540638.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/542-large_default/advanced-pack-hydra-bright-collagen-eye-restoring-pads-669203926694.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/692-large_default/advanced-pack-hydra-bright-collagen-eye-restoring-pads-669203926694.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/691-large_default/advanced-pack-hydra-bright-collagen-eye-restoring-pads-669203926694.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1214-large_default/advanced-pack-hydra-bright-collagen-eye-restoring-pads-669203926694.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1069-large_default/advanced-pack-hydra-bright-collagen-eye-restoring-pads-669203926694.jpg]]></g:additional_image_link>
<g:price>59.00 GBP</g:price>
<g:sale_price>44.25 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Eye Care]]></g:product_type>
<g:gtin><![CDATA[5060762540638]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.057 匁</g:shipping_weight>
</entry>

<entry>
<g:id>179</g:id>
<g:title><![CDATA[Essential Pack - Hydra-Bright Collagen Eye Restoring Pads]]></g:title>
<g:description><![CDATA[Wake up to brighter eyes with this revolutionary eye set, saturated with a complex combination of active ingredients. It aims to smooth, intensely hydrate and minimise the appearance of fine lines, dark circles and puffiness.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/eye-care/179-essential-pack-hydra-bright-collagen-eye-restoring-pads-669203926700-5060762540645.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/543-large_default/essential-pack-hydra-bright-collagen-eye-restoring-pads-669203926700.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/694-large_default/essential-pack-hydra-bright-collagen-eye-restoring-pads-669203926700.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/693-large_default/essential-pack-hydra-bright-collagen-eye-restoring-pads-669203926700.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1213-large_default/essential-pack-hydra-bright-collagen-eye-restoring-pads-669203926700.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1070-large_default/essential-pack-hydra-bright-collagen-eye-restoring-pads-669203926700.jpg]]></g:additional_image_link>
<g:price>34.00 GBP</g:price>
<g:sale_price>25.50 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Eye Care]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762540645]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.036 匁</g:shipping_weight>
</entry>

<entry>
<g:id>180</g:id>
<g:title><![CDATA[Single Pack - Hydra-Bright Collagen Eye Restoring Pads]]></g:title>
<g:description><![CDATA[Wake up to brighter eyes with this revolutionary eye set, saturated with a complex combination of active ingredients. It aims to smooth, intensely hydrate and minimise the appearance of fine lines, dark circles and puffiness.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/eye-care/180-single-pack-hydra-bright-collagen-eye-restoring-pads-669203926588-5060762540737.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/544-large_default/single-pack-hydra-bright-collagen-eye-restoring-pads-669203926588.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1212-large_default/single-pack-hydra-bright-collagen-eye-restoring-pads-669203926588.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1116-large_default/single-pack-hydra-bright-collagen-eye-restoring-pads-669203926588.jpg]]></g:additional_image_link>
<g:price>12.00 GBP</g:price>
<g:sale_price>9.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Eye Care]]></g:product_type>
<g:gtin><![CDATA[5060762540737]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.010 匁</g:shipping_weight>
</entry>

<entry>
<g:id>195</g:id>
<g:title><![CDATA[Pro Salicylic Blue Minerals Clarifying Blemish & Imperfections Exfoliator]]></g:title>
<g:description><![CDATA[This gentle, clarifying exfoliator is specifically designed to target localised blemishes and imperfections to help purify the skin and refine texture without drying it out.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/masks-exfoliators/195-pro-salicylic-blue-minerals-clarifying-blemish-imperfections-exfoliator-669203926724-5060762541277.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1274-large_default/pro-salicylic-blue-minerals-clarifying-blemish-imperfections-exfoliator-669203926724.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1275-large_default/pro-salicylic-blue-minerals-clarifying-blemish-imperfections-exfoliator-669203926724.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1204-large_default/pro-salicylic-blue-minerals-clarifying-blemish-imperfections-exfoliator-669203926724.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/982-large_default/pro-salicylic-blue-minerals-clarifying-blemish-imperfections-exfoliator-669203926724.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1074-large_default/pro-salicylic-blue-minerals-clarifying-blemish-imperfections-exfoliator-669203926724.jpg]]></g:additional_image_link>
<g:price>74.00 GBP</g:price>
<g:sale_price>39.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Masks & Exfoliators]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762541277]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.164 匁</g:shipping_weight>
</entry>

<entry>
<g:id>196</g:id>
<g:title><![CDATA[Pro Salicylic Immediate Blemish S.O.S Treatment]]></g:title>
<g:description><![CDATA[Encourage a clear, healthy-looking complexion with the Pro Salicylic Immediate Blemish S.O.S Treatment, a powerful localised treatment fortified with revolutionary ingredients]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/serums/196-pro-salicylic-immediate-blemish-sos-treatment-669203926717-5060762541260.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/771-large_default/pro-salicylic-immediate-blemish-sos-treatment-669203926717.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/772-large_default/pro-salicylic-immediate-blemish-sos-treatment-669203926717.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1207-large_default/pro-salicylic-immediate-blemish-sos-treatment-669203926717.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/773-large_default/pro-salicylic-immediate-blemish-sos-treatment-669203926717.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/981-large_default/pro-salicylic-immediate-blemish-sos-treatment-669203926717.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1073-large_default/pro-salicylic-immediate-blemish-sos-treatment-669203926717.jpg]]></g:additional_image_link>
<g:price>72.00 GBP</g:price>
<g:sale_price>54.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Serums]]></g:product_type>
<g:gtin><![CDATA[5060762541260]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.025 匁</g:shipping_weight>
</entry>

<entry>
<g:id>207</g:id>
<g:title><![CDATA[Brightening & De-Puffing Hyaluronic Overnight Eye Recovery Mask]]></g:title>
<g:description><![CDATA[The latest addition to Avant's eye care series, the Brightening &amp; De-Puffing Hyaluronic Overnight Eye Recovery Mask is fortified with revolutionary ingredients specifically formulated for the delicate eye area]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/eye-care/207-brightening-de-puffing-hyaluronic-overnight-eye-recovery-mask-5060762541215-5060762541215.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/983-large_default/brightening-de-puffing-hyaluronic-overnight-eye-recovery-mask-5060762541215.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2029-large_default/brightening-de-puffing-hyaluronic-overnight-eye-recovery-mask-5060762541215.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2030-large_default/brightening-de-puffing-hyaluronic-overnight-eye-recovery-mask-5060762541215.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1120-large_default/brightening-de-puffing-hyaluronic-overnight-eye-recovery-mask-5060762541215.jpg]]></g:additional_image_link>
<g:price>74.00 GBP</g:price>
<g:sale_price>55.50 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Eye Care]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762541215]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.020 匁</g:shipping_weight>
</entry>

<entry>
<g:id>211</g:id>
<g:title><![CDATA[Eight Hour Retexturing  & Anti-Oxidising Hyaluronic Facial Serum]]></g:title>
<g:description><![CDATA[A revolutionary Serum working to combat free radicals and protect against the pollution. It is formulated to redefine the skin texture thanks to powerful hydrating ingredients. ]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/serums/211-eight-hour-retexturing-anti-oxidising-hyaluronic-facial-serum-647297417113-647297417113.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/825-large_default/eight-hour-retexturing-anti-oxidising-hyaluronic-facial-serum-647297417113.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1094-large_default/eight-hour-retexturing-anti-oxidising-hyaluronic-facial-serum-647297417113.jpg]]></g:additional_image_link>
<g:price>89.00 GBP</g:price>
<g:sale_price>66.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Serums]]></g:product_type>
<g:gtin><![CDATA[647297417113]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.090 匁</g:shipping_weight>
</entry>

<entry>
<g:id>217</g:id>
<g:title><![CDATA[Avant Gift Box -  5-Step Routine]]></g:title>
<g:description><![CDATA[This routine aims to deeply hydrate skin and rejuvenate tired or dull skin. Perfect for those seeking revitalised and reinvigorated skin. Includes:  Pro-Intense Hyaluronic Acid Illuminating Day Cream 50ml Eight Hour Retexturing & Anti-Oxidising Hyaluronic Facial Serum 30ml Deluxe Hyaluronic Acid Vivifying Face and Eye Night Cream 50ml Hyaluronic Acid Molecular Boost Eye Cream 10ml Moisture Surge Overnight Treatment 50ml]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/gift-boxes/217-avant-5-step-routine-gift-box-669203927431-669203927431.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1391-large_default/avant-5-step-routine-gift-box-669203927431.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1399-large_default/avant-5-step-routine-gift-box-669203927431.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1400-large_default/avant-5-step-routine-gift-box-669203927431.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1401-large_default/avant-5-step-routine-gift-box-669203927431.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/871-large_default/avant-5-step-routine-gift-box-669203927431.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1132-large_default/avant-5-step-routine-gift-box-669203927431.jpg]]></g:additional_image_link>
<g:price>379.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Gift Boxes]]></g:product_type>
<g:gtin><![CDATA[669203927431]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.820 匁</g:shipping_weight>
</entry>

<entry>
<g:id>218</g:id>
<g:title><![CDATA[Avant Gift Box - 3-Step Infinite Youth Routine]]></g:title>
<g:description><![CDATA[This routine contains the latest in anti-ageing innovation and aims to firm and keep your skin youthful and glowing from every angle. Includes:  Pro-Intense Hyaluronic Acid Illuminating Day Cream 50ml R.N.A Radical Firmness Anti-Ageing Serum 30ml Deluxe Hyaluronic Acid Vivifying Face and Eye Night Cream 50ml]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/gift-boxes/218-avant-infinite-youth-gift-box-669203927448-669203927448.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1392-large_default/avant-infinite-youth-gift-box-669203927448.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1407-large_default/avant-infinite-youth-gift-box-669203927448.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1404-large_default/avant-infinite-youth-gift-box-669203927448.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1405-large_default/avant-infinite-youth-gift-box-669203927448.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/870-large_default/avant-infinite-youth-gift-box-669203927448.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1133-large_default/avant-infinite-youth-gift-box-669203927448.jpg]]></g:additional_image_link>
<g:price>269.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Gift Boxes]]></g:product_type>
<g:gtin><![CDATA[669203927448]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.600 匁</g:shipping_weight>
</entry>

<entry>
<g:id>221</g:id>
<g:title><![CDATA[Flawless Energising & Oxygenating Caffeine 2-1 Face & Eye Serum]]></g:title>
<g:description><![CDATA[A lightweight, dynamising serum that aims to blanket the skin in pure oxygen, providing it with vivid rejuvenation. Fortified with innovative ingredients, this formula helps protect against environmental pollution and free radical damage, leaving complexion radiant and invigorated.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/eye-care/221-flawless-energising-oxygenating-caffeine-2-1-face-eye-serum-5060762541345-5060762541345.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/880-large_default/flawless-energising-oxygenating-caffeine-2-1-face-eye-serum-5060762541345.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/889-large_default/flawless-energising-oxygenating-caffeine-2-1-face-eye-serum-5060762541345.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/884-large_default/flawless-energising-oxygenating-caffeine-2-1-face-eye-serum-5060762541345.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/984-large_default/flawless-energising-oxygenating-caffeine-2-1-face-eye-serum-5060762541345.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1077-large_default/flawless-energising-oxygenating-caffeine-2-1-face-eye-serum-5060762541345.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2090-large_default/flawless-energising-oxygenating-caffeine-2-1-face-eye-serum-5060762541345.jpg]]></g:additional_image_link>
<g:price>92.00 GBP</g:price>
<g:sale_price>69.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Eye Care]]></g:product_type>
<g:gtin><![CDATA[5060762541345]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.102 匁</g:shipping_weight>
</entry>

<entry>
<g:id>222</g:id>
<g:title><![CDATA[Pink Orchid Subtle Restoring Overnight Serum]]></g:title>
<g:description><![CDATA[Designed to support the skin during sleep, this overnight serum helps replenish the skin’s essential moisture reserves and lessen dry patches that can make skin look dull, relieving the most common signs of stress and fatigue.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/eye-care/222-pink-orchid-subtle-restoring-overnight-serum-5060762541352-5060762541352.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/881-large_default/pink-orchid-subtle-restoring-overnight-serum-5060762541352.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/890-large_default/pink-orchid-subtle-restoring-overnight-serum-5060762541352.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/885-large_default/pink-orchid-subtle-restoring-overnight-serum-5060762541352.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/985-large_default/pink-orchid-subtle-restoring-overnight-serum-5060762541352.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1078-large_default/pink-orchid-subtle-restoring-overnight-serum-5060762541352.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1909-large_default/pink-orchid-subtle-restoring-overnight-serum-5060762541352.jpg]]></g:additional_image_link>
<g:price>89.00 GBP</g:price>
<g:sale_price>66.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Eye Care]]></g:product_type>
<g:gtin><![CDATA[5060762541352]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.102 匁</g:shipping_weight>
</entry>

<entry>
<g:id>223</g:id>
<g:title><![CDATA[Marvellous Nocturnal Resurfacing Hyaluronic Facial Serum]]></g:title>
<g:description><![CDATA[Designed to address multiple signs of ageing in one powerful yet lightweight formula, this fast-absorbing night serum is inspired by research into skin volume and line-relaxing, aiming to significantly re-establish volume, visibly plumping and re-contouring for firmer skin with increased resilience.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/serums/223-marvellous-nocturnal-resurfacing-hyaluronic-facial-serum-5060762541369-5060762541369.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/882-large_default/marvellous-nocturnal-resurfacing-hyaluronic-facial-serum-5060762541369.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/891-large_default/marvellous-nocturnal-resurfacing-hyaluronic-facial-serum-5060762541369.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/886-large_default/marvellous-nocturnal-resurfacing-hyaluronic-facial-serum-5060762541369.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/986-large_default/marvellous-nocturnal-resurfacing-hyaluronic-facial-serum-5060762541369.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1079-large_default/marvellous-nocturnal-resurfacing-hyaluronic-facial-serum-5060762541369.jpg]]></g:additional_image_link>
<g:price>94.00 GBP</g:price>
<g:sale_price>70.50 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Serums]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762541369]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.102 匁</g:shipping_weight>
</entry>

<entry>
<g:id>224</g:id>
<g:title><![CDATA[Revolumising & Revitalising Biotin Scalp & Hair Treatment]]></g:title>
<g:description><![CDATA[A prodigious product with an ultra light, non-greasy texture, highly concentrated with plant-based extracts, proteins and vitamins, aiming to energise the hair follicle to help the hair to anchor, making it more resistant while nourishing and refreshing the scalp.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hair-nutri-replenish/224-revolumising-revitalising-biotin-scalp-hair-treatment-5060762541567-5060762541567.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1270-large_default/revolumising-revitalising-biotin-scalp-hair-treatment-5060762541567.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/892-large_default/revolumising-revitalising-biotin-scalp-hair-treatment-5060762541567.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/887-large_default/revolumising-revitalising-biotin-scalp-hair-treatment-5060762541567.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1184-large_default/revolumising-revitalising-biotin-scalp-hair-treatment-5060762541567.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1125-large_default/revolumising-revitalising-biotin-scalp-hair-treatment-5060762541567.jpg]]></g:additional_image_link>
<g:price>79.00 GBP</g:price>
<g:sale_price>59.25 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Range > Hair Nutri-replenish]]></g:product_type>
<g:gtin><![CDATA[5060762541567]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.091 匁</g:shipping_weight>
</entry>

<entry>
<g:id>228</g:id>
<g:title><![CDATA[Expert Biotin Restoring & Restructuring Hair Mask-in-Serum]]></g:title>
<g:description><![CDATA[The revolutionary formula works to leave you with thicker, smoother, healthier hair, whilst deeply cleansing and restoring pH levels]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hair-nutri-replenish/228-expert-biotin-restoring-restructuring-hair-mask-in-serum-5060762541574-5060762541574.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1014-large_default/expert-biotin-restoring-restructuring-hair-mask-in-serum-5060762541574.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1187-large_default/expert-biotin-restoring-restructuring-hair-mask-in-serum-5060762541574.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1186-large_default/expert-biotin-restoring-restructuring-hair-mask-in-serum-5060762541574.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1185-large_default/expert-biotin-restoring-restructuring-hair-mask-in-serum-5060762541574.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1126-large_default/expert-biotin-restoring-restructuring-hair-mask-in-serum-5060762541574.jpg]]></g:additional_image_link>
<g:price>74.00 GBP</g:price>
<g:sale_price>55.50 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Range > Hair Nutri-replenish]]></g:product_type>
<g:gtin><![CDATA[5060762541574]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.214 匁</g:shipping_weight>
</entry>

<entry>
<g:id>229</g:id>
<g:title><![CDATA[Protecting & Restoring Keratin-Infused Hair Therapy]]></g:title>
<g:description><![CDATA[Awarded Marie Claire Hair Awards Best Shine Product 2022, our keratin-infused hair therapy combines the perfect combination of ingredients to help smooth, thicken, moisturise your hair, leaving you with luscious locks.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hair-nutri-replenish/229-protecting-restoring-keratin-infused-hair-therapy-5060762541581-5060762541581.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1016-large_default/protecting-restoring-keratin-infused-hair-therapy-5060762541581.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1189-large_default/protecting-restoring-keratin-infused-hair-therapy-5060762541581.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1188-large_default/protecting-restoring-keratin-infused-hair-therapy-5060762541581.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1190-large_default/protecting-restoring-keratin-infused-hair-therapy-5060762541581.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1569-large_default/protecting-restoring-keratin-infused-hair-therapy-5060762541581.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1127-large_default/protecting-restoring-keratin-infused-hair-therapy-5060762541581.jpg]]></g:additional_image_link>
<g:price>77.00 GBP</g:price>
<g:sale_price>57.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Range > Hair Nutri-replenish]]></g:product_type>
<g:gtin><![CDATA[5060762541581]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.214 匁</g:shipping_weight>
</entry>

<entry>
<g:id>230</g:id>
<g:title><![CDATA[Reconstructing Keratin Complex Ultra Shine Hair Cream]]></g:title>
<g:description><![CDATA[This innovative hair reconstructing formula aims to leave hair smooth and radiant whilst stimulating growth and reducing scalp irritation.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hair-nutri-replenish/230-reconstructing-keratin-complex-ultra-shine-hair-cream-5060762541598-5060762541598.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1018-large_default/reconstructing-keratin-complex-ultra-shine-hair-cream-5060762541598.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1192-large_default/reconstructing-keratin-complex-ultra-shine-hair-cream-5060762541598.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1191-large_default/reconstructing-keratin-complex-ultra-shine-hair-cream-5060762541598.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1128-large_default/reconstructing-keratin-complex-ultra-shine-hair-cream-5060762541598.jpg]]></g:additional_image_link>
<g:price>67.00 GBP</g:price>
<g:sale_price>50.25 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Range > Hair Nutri-replenish]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762541598]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.240 匁</g:shipping_weight>
</entry>

<entry>
<g:id>234</g:id>
<g:title><![CDATA[Deep Pore Oxygenating & Purifying Kaolin Bubble Clay Mask]]></g:title>
<g:description><![CDATA[Winner of Pure Beauty Global Awards 2022 - Best New Face Mask the Deep Pore-Oxygenating &amp; Purifying Kaolin Bubble Clay Mask aims to balance and decongest problematic skin. A powerful pairing of natural Kaolin and Activated Charcoal works to go deep within the pores to remove blockages and excess sebum.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/masks-exfoliators/234-deep-pore-oxygenating-purifying-kaolin-bubble-clay-mask-5060762541758.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1688-large_default/deep-pore-oxygenating-purifying-kaolin-bubble-clay-mask.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1571-large_default/deep-pore-oxygenating-purifying-kaolin-bubble-clay-mask.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1309-large_default/deep-pore-oxygenating-purifying-kaolin-bubble-clay-mask.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1308-large_default/deep-pore-oxygenating-purifying-kaolin-bubble-clay-mask.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1310-large_default/deep-pore-oxygenating-purifying-kaolin-bubble-clay-mask.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:sale_price>63.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Masks & Exfoliators]]></g:product_type>
<g:gtin><![CDATA[5060762541758]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.162 匁</g:shipping_weight>
</entry>

<entry>
<g:id>235</g:id>
<g:title><![CDATA[Intense Cupuacu Replenishing & Nourishing 2-1 Lip-Repair Mask]]></g:title>
<g:description><![CDATA[A hydrating lip mask packed with antioxidants and natural emollients to nourish dry lips. Formulated with Cupuacu and Shea Butter, it aims to hydrate and retexture your lips, leaving them feeling soothed and conditioned.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/age-nutri-revive/235-intense-cupuacu-replenishing-nourishing-2-1-lip-repair-mask-5060762541741.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1306-large_default/intense-cupuacu-replenishing-nourishing-2-1-lip-repair-mask.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1305-large_default/intense-cupuacu-replenishing-nourishing-2-1-lip-repair-mask.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1307-large_default/intense-cupuacu-replenishing-nourishing-2-1-lip-repair-mask.jpg]]></g:additional_image_link>
<g:price>54.00 GBP</g:price>
<g:sale_price>40.50 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Range > Age Nutri-Revive]]></g:product_type>
<g:gtin><![CDATA[5060762541741]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.100 匁</g:shipping_weight>
</entry>

<entry>
<g:id>243</g:id>
<g:title><![CDATA[Persian Sunset]]></g:title>
<g:description><![CDATA[Persian Sunset demonstrates the perfect harnessing of exotic elegance. It would be impossible not to feel the sun on your skin and the sand between your toes when spritzing this  delightfully warming fragrance. Its alluring orange colour represents that of the most gorgeous sunset.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/perfumes/243-persian-sunset--5060762541680.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1505-large_default/persian-sunset-.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1489-large_default/persian-sunset-.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1488-large_default/persian-sunset-.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2049-large_default/persian-sunset-.jpg]]></g:additional_image_link>
<g:price>114.00 GBP</g:price>
<g:sale_price>85.50 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Perfumes]]></g:product_type>
<g:gtin><![CDATA[5060762541680]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.326 匁</g:shipping_weight>
</entry>

<entry>
<g:id>244</g:id>
<g:title><![CDATA[Ambuscade]]></g:title>
<g:description><![CDATA[A deep fruity fragrance with a hint of spice, a spritz of Ambuscade feels right all year round. In the warm summer, it is the first sip of the coolest berry cocktail touching your lips. In the cold winter, it is the warmest spiced concoction by the fire. Its rich red colour represents strength and passion.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/perfumes/244-ambuscade--5060762541697.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1504-large_default/ambuscade-.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1487-large_default/ambuscade-.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1486-large_default/ambuscade-.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2050-large_default/ambuscade-.jpg]]></g:additional_image_link>
<g:price>119.00 GBP</g:price>
<g:sale_price>89.25 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Perfumes]]></g:product_type>
<g:gtin><![CDATA[5060762541697]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.326 匁</g:shipping_weight>
</entry>

<entry>
<g:id>245</g:id>
<g:title><![CDATA[Caress]]></g:title>
<g:description><![CDATA[Caress is a fragrance of synaesthesia, its scent evokes the sensuality of a gentle touch. It combines the fresh luxury of orchid with the comfort of high quality cashmere gliding over the skin. Its pale pink colour resembles that of fresh sheets and early sunrises. ]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/perfumes/245-caress--5060762541703.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1503-large_default/caress-.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1485-large_default/caress-.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1484-large_default/caress-.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2051-large_default/caress-.jpg]]></g:additional_image_link>
<g:price>112.00 GBP</g:price>
<g:sale_price>84.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Perfumes]]></g:product_type>
<g:gtin><![CDATA[5060762541703]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.326 匁</g:shipping_weight>
</entry>

<entry>
<g:id>247</g:id>
<g:title><![CDATA[Pure Emerald]]></g:title>
<g:description><![CDATA[One spritz of Pure Emerald will transport you exactly where you want to go. It is a deep shade of green, linking the world of nature to that of luxury. Whether your idea of heaven is a secluded glade bejewelled with wildflowers or the sweetest cotton candy cloud at the seaside, this fragrance will take you there.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/perfumes/247-pure-emerald--5060762541710.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1502-large_default/pure-emerald-.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1480-large_default/pure-emerald-.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1481-large_default/pure-emerald-.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2052-large_default/pure-emerald-.jpg]]></g:additional_image_link>
<g:price>117.00 GBP</g:price>
<g:sale_price>87.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Perfumes]]></g:product_type>
<g:gtin><![CDATA[5060762541710]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.326 匁</g:shipping_weight>
</entry>

<entry>
<g:id>251</g:id>
<g:title><![CDATA[Rose Isparta Cleansing Bar]]></g:title>
<g:description><![CDATA[Intensify your bathing experience with the luxurious, aromatic scent of the Rose Isparta cleansing bar. Let the aromas take you to the rose feilds of Isparta, Turkey and let your body indulge in the beautifying and detoxifying effects Rose has on the skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/age-nutri-revive/251-rose-isparta-cleansing-bar-5060762541956.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1303-large_default/rose-isparta-cleansing-bar.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1311-large_default/rose-isparta-cleansing-bar.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1313-large_default/rose-isparta-cleansing-bar.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1288-large_default/rose-isparta-cleansing-bar.jpg]]></g:additional_image_link>
<g:price>27.00 GBP</g:price>
<g:sale_price>20.25 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Range > Age Nutri-Revive]]></g:product_type>
<g:gtin><![CDATA[5060762541956]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.112 匁</g:shipping_weight>
</entry>

<entry>
<g:id>253</g:id>
<g:title><![CDATA[Hyalu Replenish Lip Balm]]></g:title>
<g:description><![CDATA[For satin-smooth, hydrated lips, this soothing balm aim to leave lips irresistibly soft, youthful and nourished.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/age-nutri-revive/253-hyalu-replenish-lip-balm-5060762541949.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1425-large_default/hyalu-replenish-lip-balm.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1424-large_default/hyalu-replenish-lip-balm.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1739-large_default/hyalu-replenish-lip-balm.jpg]]></g:additional_image_link>
<g:price>54.00 GBP</g:price>
<g:sale_price>40.50 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Range > Age Nutri-Revive]]></g:product_type>
<g:gtin><![CDATA[5060762541949]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.013 匁</g:shipping_weight>
</entry>

<entry>
<g:id>254</g:id>
<g:title><![CDATA[Pro-Elongating Biotin Lash & Brow Fortifier Serum]]></g:title>
<g:description><![CDATA[Our Pro-Elongating Biotin Lash & Brow Fortifier Serum is  designed for those with short, thin, fragile lashes, needing a precise enhancement. It will work to upgrade the looks of lashes without the need for false extensions. This lightweight, quick and easy to use serum is packed full off essential ingredients that help boost your lashes growth, fullness, and length.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/age-nutri-revive/254-pro-elongating-biotin-lash-brow-fortifier-serum-5060762541963.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1507-large_default/pro-elongating-biotin-lash-brow-fortifier-serum.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1506-large_default/pro-elongating-biotin-lash-brow-fortifier-serum.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1508-large_default/pro-elongating-biotin-lash-brow-fortifier-serum.jpg]]></g:additional_image_link>
<g:price>74.00 GBP</g:price>
<g:sale_price>55.50 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Range > Age Nutri-Revive]]></g:product_type>
<g:gtin><![CDATA[5060762541963]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.027 匁</g:shipping_weight>
</entry>

<entry>
<g:id>255</g:id>
<g:title><![CDATA[Avant Gift Box - 4-Step Radiant Routine]]></g:title>
<g:description><![CDATA[Discover your outer radiance with the Avant 4-Step Radiant Routine. This regimen will work to buff away tired, dull skin cells to reveal nourished, youthful fresh skin underneath. A key ingredient in this set is Rose -the perfect ingredient for helping renew dead skin cells and brighten the complexion.  Includes: Gentle Rose Beautifying Face Exfoliant 50ml Antioxidising & Detoxifying Rose Serum 30ml Supreme Hyaluronic Acid Antioxidising DUO Moisturiser 50ml Pro-Radiance Brightening Eye Final Touch 10ml]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/gift-boxes/255-4-step-routine-669203927622-669203927622.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1382-large_default/4-step-routine-669203927622.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1422-large_default/4-step-routine-669203927622.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1320-large_default/4-step-routine-669203927622.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1419-large_default/4-step-routine-669203927622.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1421-large_default/4-step-routine-669203927622.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1318-large_default/4-step-routine-669203927622.jpg]]></g:additional_image_link>
<g:price>339.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Gift Boxes]]></g:product_type>
<g:gtin><![CDATA[669203927622]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.600 匁</g:shipping_weight>
</entry>

<entry>
<g:id>256</g:id>
<g:title><![CDATA[Avant Gift Box - 3-Step Bio-Restorative Routine]]></g:title>
<g:description><![CDATA[Indulge your skin in a more natural skincare routine. The 3-Step Bio-Restorative Routine aims to provide noticeable results with more natural formulas.  Includes: LIMITED EDITION Advanced Bio Radiance Invigorating Concentrate Serum 30ml Advanced Bio Ultra-Fine Texture Day Moisturiser 50ml  Advanced Bio Regenerating Overnight Treatment 50ml]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/gift-boxes/256-avant-gift-box-3-step-bio-restorative-routine-669203927660.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1383-large_default/avant-gift-box-3-step-bio-restorative-routine.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1408-large_default/avant-gift-box-3-step-bio-restorative-routine.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1334-large_default/avant-gift-box-3-step-bio-restorative-routine.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1410-large_default/avant-gift-box-3-step-bio-restorative-routine.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1409-large_default/avant-gift-box-3-step-bio-restorative-routine.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1323-large_default/avant-gift-box-3-step-bio-restorative-routine.jpg]]></g:additional_image_link>
<g:price>234.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Gift Boxes]]></g:product_type>
<g:gtin><![CDATA[669203927660]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.600 匁</g:shipping_weight>
</entry>

<entry>
<g:id>257</g:id>
<g:title><![CDATA[Avant Gift Box - 4-Step Purification-Intense Routine]]></g:title>
<g:description><![CDATA[If you suffer from dry and lacklustre skin this routine is designed for you. Look to give your skin the revitalisation it needs by adding these 4 specially selected products to your routine.  Includes: Blue Volcanic Stone Purifying &amp; Antioxidising Cleansing Gel 100ml Proactive Mandelic Acid Restoring & Anti-Pollution Toner 200ml Supreme Hyaluronic Acid Antioxidising DUO Moisturiser 50ml Damascan Rose Petals Antioxidising &amp; Retexturing Treatment Mask 50ml]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/gift-boxes/257-4-Step-Purification-Intense-Routine-669203927509-669203927509.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1384-large_default/4-Step-Purification-Intense-Routine-669203927509.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1411-large_default/4-Step-Purification-Intense-Routine-669203927509.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1331-large_default/4-Step-Purification-Intense-Routine-669203927509.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1412-large_default/4-Step-Purification-Intense-Routine-669203927509.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1413-large_default/4-Step-Purification-Intense-Routine-669203927509.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1327-large_default/4-Step-Purification-Intense-Routine-669203927509.jpg]]></g:additional_image_link>
<g:price>309.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Gift Boxes]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[669203927509]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.600 匁</g:shipping_weight>
</entry>

<entry>
<g:id>270</g:id>
<g:title><![CDATA[Limited-Edition Wellness Pack]]></g:title>
<g:description><![CDATA[A skincare routine, like any other form of self-care, can be comforting and soothing by nature. There’s something slightly meditative about taking time out for yourself as a way to unwind from the hectic pressures of modern-day life. We have curated skincare routine to help you indulge in some skincare selfcare to help beat the January blues. Includes: - 8 hour Radiance Renewal Sleeping Mask (50ml) - Glycolic Acid Vivifying & Firming Body Treatment (50ml) - Hydra-Bright Collagen Eye Restoring Eye Pads (3 Pack) - Hyalu Replenish Lip Balm (10ml) - Avant Recycled Cotton Washbag]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/limited-edition/270-set-limited-edition-wellness-pack-6pcs-5060762543295.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1449-large_default/set-limited-edition-wellness-pack-6pcs.jpg]]></g:image_link>
<g:price>129.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Range > Limited Edition]]></g:product_type>
<g:gtin><![CDATA[5060762543295]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
</entry>

<entry>
<g:id>271</g:id>
<g:title><![CDATA[Serene Scalp Recovery Exfoliating Treatment]]></g:title>
<g:description><![CDATA[Awarded Top Sante's Best Scalp Treatment For Fine Hair 2022, our Serene Scalp Recovery Exfoliating Treatment works as a spring-clean for your scalp, working to sweep away build-up, balancing oils and fighting flakes to keep your hair follicles healthy.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hair/271-serene-scalp-recovery-exfoliating-treatment-5060762541963.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1521-large_default/serene-scalp-recovery-exfoliating-treatment.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1428-large_default/serene-scalp-recovery-exfoliating-treatment.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1429-large_default/serene-scalp-recovery-exfoliating-treatment.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2040-large_default/serene-scalp-recovery-exfoliating-treatment.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1772-large_default/serene-scalp-recovery-exfoliating-treatment.jpg]]></g:additional_image_link>
<g:price>72.00 GBP</g:price>
<g:sale_price>54.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Hair]]></g:product_type>
<g:gtin><![CDATA[5060762541963]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.112 匁</g:shipping_weight>
</entry>

<entry>
<g:id>272</g:id>
<g:title><![CDATA[Re-Shaping Caffeine Anti-Cellulite Contouring Cream-Gel]]></g:title>
<g:description><![CDATA[The Re-Shaping Caffeine Anti-Cellulite Contouring Cream-Gel is a specific treatment formulated to combat all the signs of cellulite: fatty nodules, orange peel skin, retention of skin liquids.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/by-category/272-re-shaping-caffeine-anti-cellulite-contouring-cream-gel-5060762543011.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1450-large_default/re-shaping-caffeine-anti-cellulite-contouring-cream-gel.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1431-large_default/re-shaping-caffeine-anti-cellulite-contouring-cream-gel.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1430-large_default/re-shaping-caffeine-anti-cellulite-contouring-cream-gel.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:sale_price>63.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category]]></g:product_type>
<g:gtin><![CDATA[5060762543011]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.128 匁</g:shipping_weight>
</entry>

<entry>
<g:id>273</g:id>
<g:title><![CDATA[Ultra Marine Collagen Hair Nail and Skin Beauty Complex]]></g:title>
<g:description><![CDATA[An advanced formula designed to deliver a unique blend of super quality nutrients to moisturise your skin from within, increase plumpness, reduce wrinkles, improve bone and joint health and boost collagen production throughout the body to fight the signs of ageing.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/food-supplements/273-ultra-marine-collagen-hair-nail-and-skin-beauty-complex-5060762541963.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1453-large_default/ultra-marine-collagen-hair-nail-and-skin-beauty-complex.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1496-large_default/ultra-marine-collagen-hair-nail-and-skin-beauty-complex.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1454-large_default/ultra-marine-collagen-hair-nail-and-skin-beauty-complex.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1497-large_default/ultra-marine-collagen-hair-nail-and-skin-beauty-complex.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1452-large_default/ultra-marine-collagen-hair-nail-and-skin-beauty-complex.jpg]]></g:additional_image_link>
<g:price>74.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:product_type><![CDATA[Shop > By Category > Food Supplements]]></g:product_type>
<g:gtin><![CDATA[5060762541963]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.063 匁</g:shipping_weight>
</entry>

<entry>
<g:id>274</g:id>
<g:title><![CDATA[Bio Marine Collagen Radiance Activator Capsules]]></g:title>
<g:description><![CDATA[Our Bio Marine Collagen Radiance Activator Capsules work to nourish your skin from within by helping to restore your body's natural level of Hyaluronic acid and Collagen in the skin. Increased levels of these two elements contribute to improving skin hydration, maintaining firm skin and counteracting the visible signs of ageing. ]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/food-supplements/274-bio-marine-collagen-radiance-activator-capsules-5060762541963.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1456-large_default/bio-marine-collagen-radiance-activator-capsules.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1498-large_default/bio-marine-collagen-radiance-activator-capsules.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1455-large_default/bio-marine-collagen-radiance-activator-capsules.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1499-large_default/bio-marine-collagen-radiance-activator-capsules.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1457-large_default/bio-marine-collagen-radiance-activator-capsules.jpg]]></g:additional_image_link>
<g:price>62.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:product_type><![CDATA[Shop > By Category > Food Supplements]]></g:product_type>
<g:gtin><![CDATA[5060762541963]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.063 匁</g:shipping_weight>
</entry>

<entry>
<g:id>275</g:id>
<g:title><![CDATA[Dynamic Hydra-Balancing & Firming Pullulan Night Moisturiser]]></g:title>
<g:description><![CDATA[The Dynamic Hydra-Balancing &amp; Firming Pullulan Night Moisturiser works to boost radiance and refresh the complexion overnight. It helps counteract the signs of skin ageing, such as wrinkles caused by dryness and dark under-eye circles.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/night-creams/275-dynamic-hydra-balancing-firming-pullulan-night-moisturiser-5060762541345.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1549-large_default/dynamic-hydra-balancing-firming-pullulan-night-moisturiser.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1547-large_default/dynamic-hydra-balancing-firming-pullulan-night-moisturiser.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1437-large_default/dynamic-hydra-balancing-firming-pullulan-night-moisturiser.jpg]]></g:additional_image_link>
<g:price>74.00 GBP</g:price>
<g:sale_price>55.50 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Night creams]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762541345]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.067 匁</g:shipping_weight>
</entry>

<entry>
<g:id>277</g:id>
<g:title><![CDATA[Blue Volcanic Stone Purifying & Antioxidising Cleansing Gel]]></g:title>
<g:description><![CDATA[A mild, non-drying gel, this aims to effortlessly eliminate excess oil, dirt and make up without leaving skin feeling over-exposed. With a cocktail of antibacterial, anti-inflammatory and antioxidant extracts, the Blue Volcanic Stone Purifying & Antioxydising Cleansing Gel helps prevent breakouts and soothe irritation. The perfect first step in your skincare regime.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/eye-care/277-blue-volcanic-stone-purifying-antioxidising-cleansing-gel-5060762541345.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1759-large_default/blue-volcanic-stone-purifying-antioxidising-cleansing-gel.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1447-large_default/blue-volcanic-stone-purifying-antioxidising-cleansing-gel.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1448-large_default/blue-volcanic-stone-purifying-antioxidising-cleansing-gel.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1444-large_default/blue-volcanic-stone-purifying-antioxidising-cleansing-gel.jpg]]></g:additional_image_link>
<g:price>57.00 GBP</g:price>
<g:sale_price>42.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Eye Care]]></g:product_type>
<g:gtin><![CDATA[5060762541345]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.067 匁</g:shipping_weight>
</entry>

<entry>
<g:id>280</g:id>
<g:title><![CDATA[Intensive Restoring and Detoxifying Centella Cica Moisturiser]]></g:title>
<g:description><![CDATA[The Intensive Restoring and Detoxifying Centella Cica Moisturiser blankets the most sensitive skin types and works to provide intense nourishment and comfort. Packed with soothing ingredients, this gentle facial moisturiser helps restore balance back to sensitive, compromised complexions.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/moisturisers-mists/280-intensive-restoring-and-detoxifying-centella-cica-moisturiser-5060762542120.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1512-large_default/intensive-restoring-and-detoxifying-centella-cica-moisturiser.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1513-large_default/intensive-restoring-and-detoxifying-centella-cica-moisturiser.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1514-large_default/intensive-restoring-and-detoxifying-centella-cica-moisturiser.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1926-large_default/intensive-restoring-and-detoxifying-centella-cica-moisturiser.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2047-large_default/intensive-restoring-and-detoxifying-centella-cica-moisturiser.jpg]]></g:additional_image_link>
<g:price>82.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Moisturisers & Mists]]></g:product_type>
<g:gtin><![CDATA[5060762542120]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.188 匁</g:shipping_weight>
</entry>

<entry>
<g:id>281</g:id>
<g:title><![CDATA[Splendid Cica Mattifying and Restoring Serum]]></g:title>
<g:description><![CDATA[Our Splendid Cica Mattifying and Restoring Serum is a blessing for those looking to calm and soothe irritated skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/serums/281-splendid-cica-mattifying-and-restoring-serum-5060762542137.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1881-large_default/splendid-cica-mattifying-and-restoring-serum.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1879-large_default/splendid-cica-mattifying-and-restoring-serum.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1517-large_default/splendid-cica-mattifying-and-restoring-serum.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2023-large_default/splendid-cica-mattifying-and-restoring-serum.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:sale_price>63.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Serums]]></g:product_type>
<g:gtin><![CDATA[5060762542137]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.086 匁</g:shipping_weight>
</entry>

<entry>
<g:id>289</g:id>
<g:title><![CDATA[Limited Edition Grooming Kit]]></g:title>
<g:description><![CDATA[The NEW Limited-Edition Grooming Kit by Avant Skincare features full sized products to create a complete skincare regime at home or when travelling; beautifully packaged in an understated recycled cotton cosmetic bag. Blue Volcanic Stone Purifying & Antioxydising Cleansing Gel Eight-hour Anti-oxidising & Retexturing Hyaluronic Facial Serum Hydra-bright Collagen Eye Restoring Pads Deluxe Hyaluronic Acid Vivifying Face & Eye Night Cream]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/gift-boxes/289-limited-edition-grooming-kit-669203928759.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1527-large_default/limited-edition-grooming-kit.jpg]]></g:image_link>
<g:price>129.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Gift Boxes]]></g:product_type>
<g:gtin><![CDATA[669203928759]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
</entry>

<entry>
<g:id>291</g:id>
<g:title><![CDATA[Centifolia Rose Radiance Capturing Mask]]></g:title>
<g:description><![CDATA[Designed to thoroughly soothe and calm the skin, this rose mask contains powerful ingredients that will work to cleanse the skin of impurities and reduce the appearance of redness. Absorbing easily, this mask helps leave the complexion protected and feeling revitalised.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/masks-exfoliators/291-centifolia-rose-radiance-capturing-mask-5060762542601.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1534-large_default/centifolia-rose-radiance-capturing-mask.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1535-large_default/centifolia-rose-radiance-capturing-mask.jpg]]></g:additional_image_link>
<g:price>19.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Masks & Exfoliators]]></g:product_type>
<g:gtin><![CDATA[5060762542601]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.028 匁</g:shipping_weight>
</entry>

<entry>
<g:id>293</g:id>
<g:title><![CDATA[Beautifying Phytoactive Hair Bonding Elixir]]></g:title>
<g:description><![CDATA[Treat your hair to the nourishing effects of the Beautifying Phytoactive Hair Bonding Elixir, a multi-use, leave-in hair oil that works to beautify the appearance of dull hair.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hair/293-beautifying-phytoactive-hair-bonding-elixir-5060762542106.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1621-large_default/beautifying-phytoactive-hair-bonding-elixir.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1620-large_default/beautifying-phytoactive-hair-bonding-elixir.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1760-large_default/beautifying-phytoactive-hair-bonding-elixir.jpg]]></g:additional_image_link>
<g:price>69.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Hair]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762542106]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.113 匁</g:shipping_weight>
</entry>

<entry>
<g:id>294</g:id>
<g:title><![CDATA[Multi-Defense Ceramides Protecting Veil Broad-Spectrum SPF 50]]></g:title>
<g:description><![CDATA[Exceptional, powerful formula that  - Works to protect skin from UVA/UVB rays - Aims to plump skin and promote moisture retention - Works to protect against environmental agressors  - Helps soften, replenish and repair skin ]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/moisturisers-mists/294-multi-defense-ceramides-protecting-veil-broad-spectrum-spf-50-5060762543028.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1542-large_default/multi-defense-ceramides-protecting-veil-broad-spectrum-spf-50.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1541-large_default/multi-defense-ceramides-protecting-veil-broad-spectrum-spf-50.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1540-large_default/multi-defense-ceramides-protecting-veil-broad-spectrum-spf-50.jpg]]></g:additional_image_link>
<g:price>74.00 GBP</g:price>
<g:sale_price>39.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Moisturisers & Mists]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762543028]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.076 匁</g:shipping_weight>
</entry>

<entry>
<g:id>295</g:id>
<g:title><![CDATA[Pro Mandelic Clarifying Microfoliant Face Scrub]]></g:title>
<g:description><![CDATA[Powered by Mandelic acid, this scrub works to smooth texture and increase radiance whilst being gentle on skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/masks-exfoliators/295-pro-mandelic-clarifying-microfoliant-face-scrub-5060762542144.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1531-large_default/pro-mandelic-clarifying-microfoliant-face-scrub.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1532-large_default/pro-mandelic-clarifying-microfoliant-face-scrub.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1533-large_default/pro-mandelic-clarifying-microfoliant-face-scrub.jpg]]></g:additional_image_link>
<g:price>77.00 GBP</g:price>
<g:sale_price>57.75 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Masks & Exfoliators]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762542144]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.161 匁</g:shipping_weight>
</entry>

<entry>
<g:id>296</g:id>
<g:title><![CDATA[Adaptive Pearlescent Hyaluronic 2-1 Under Eye Correcting Serum]]></g:title>
<g:description><![CDATA[Expertly formulated to improve the overall appearance of the eye area, our Adaptive Pearlescent Hyaluronic 2-1 Under Eye Correcting Serum works to not only conceal the appearance of dark circles and fine lines by illuminating the area but also works to treat the signs of ageing skin such crow's feet.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/eye-care/296-adaptive-pearlescent-hyaluronic-2-1-under-eye-correcting-serum-5060762542779.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1553-large_default/adaptive-pearlescent-hyaluronic-2-1-under-eye-correcting-serum.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1552-large_default/adaptive-pearlescent-hyaluronic-2-1-under-eye-correcting-serum.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1550-large_default/adaptive-pearlescent-hyaluronic-2-1-under-eye-correcting-serum.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1551-large_default/adaptive-pearlescent-hyaluronic-2-1-under-eye-correcting-serum.jpg]]></g:additional_image_link>
<g:price>79.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Eye Care]]></g:product_type>
<g:gtin><![CDATA[5060762542779]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.026 匁</g:shipping_weight>
</entry>

<entry>
<g:id>309</g:id>
<g:title><![CDATA[Saviour Niacinamide Correcting DUO Moisturiser]]></g:title>
<g:description><![CDATA[Help prevent oily skin and minimise pores with the Saviour Niacinamide Correcting DUO Moisturiser. This hard-working DUO moisturiser is designed to help smooth and even skin tone, balance sebum production, reduce redness and help moisturise skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/moisturisers-mists/309-saviour-niacinamide-correcting-duo-moisturiser-5060762542656.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1592-large_default/saviour-niacinamide-correcting-duo-moisturiser.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1591-large_default/saviour-niacinamide-correcting-duo-moisturiser.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1590-large_default/saviour-niacinamide-correcting-duo-moisturiser.jpg]]></g:additional_image_link>
<g:price>79.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Moisturisers & Mists]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762542656]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.160 匁</g:shipping_weight>
</entry>

<entry>
<g:id>311</g:id>
<g:title><![CDATA[Centifolia Rose Radiance Capturing Mask - Advanced Pack]]></g:title>
<g:description><![CDATA[Designed to thoroughly soothe and calm the skin, this rose mask contains powerful ingredients that will work to cleanse the skin of impurities and reduce the appearance of redness. Absorbing easily, this mask helps leave the complexion protected and feeling revitalised.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/masks-exfoliators/311-centifolia-rose-radiance-capturing-mask-advanced-pack-5060762543554.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1597-large_default/centifolia-rose-radiance-capturing-mask-advanced-pack.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1596-large_default/centifolia-rose-radiance-capturing-mask-advanced-pack.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1598-large_default/centifolia-rose-radiance-capturing-mask-advanced-pack.jpg]]></g:additional_image_link>
<g:price>79.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Masks & Exfoliators]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762543554]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.164 匁</g:shipping_weight>
</entry>

<entry>
<g:id>312</g:id>
<g:title><![CDATA[Centifolia Rose Radiance Capturing Mask - Essential Pack]]></g:title>
<g:description><![CDATA[Designed to thoroughly soothe and calm the skin, this rose mask contains powerful ingredients that will work to cleanse the skin of impurities and reduce the appearance of redness. Absorbing easily, this mask helps leave the complexion protected and feeling revitalised.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/masks-exfoliators/312-centifolia-rose-radiance-capturing-mask-advanced-pack-5060762543561.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1752-large_default/centifolia-rose-radiance-capturing-mask-advanced-pack.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1751-large_default/centifolia-rose-radiance-capturing-mask-advanced-pack.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1750-large_default/centifolia-rose-radiance-capturing-mask-advanced-pack.jpg]]></g:additional_image_link>
<g:price>52.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Masks & Exfoliators]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762543561]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.103 匁</g:shipping_weight>
</entry>

<entry>
<g:id>327</g:id>
<g:title><![CDATA[Nutrient-Dense Super Seed Lip Treatment]]></g:title>
<g:description><![CDATA[Infused with nourishing hydrators, such as Jojoba and Argon oil, the Nutrient-Dense Super Seed Lip Treatment works to leave lips feeling soft, supple and soothed. The luxurious yet weightless texture aims to nourish and plump lips with moisture whilst minimising the appearance of fine lines around the mouth. ]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/eye-care/327-nutrient-dense-super-seed-lip-treatment-5060762542090.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1640-large_default/nutrient-dense-super-seed-lip-treatment.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1638-large_default/nutrient-dense-super-seed-lip-treatment.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1639-large_default/nutrient-dense-super-seed-lip-treatment.jpg]]></g:additional_image_link>
<g:price>59.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Eye Care]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762542090]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.030 匁</g:shipping_weight>
</entry>

<entry>
<g:id>336</g:id>
<g:title><![CDATA[Persian Sunset Collection]]></g:title>
<g:description><![CDATA[This two-piece gift set contains Persian Sunset (100ml) and Body Lotion (100ml), a rich cream that works to deeply nourish skin, helping leaving it soft and irresistibly scented.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hand-body/336-persian-sunset-collection-760122168568.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1732-large_default/persian-sunset-collection.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1657-large_default/persian-sunset-collection.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1730-large_default/persian-sunset-collection.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1714-large_default/persian-sunset-collection.jpg]]></g:additional_image_link>
<g:price>129.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Hand & body]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[760122168568]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
</entry>

<entry>
<g:id>337</g:id>
<g:title><![CDATA[Ambuscade Collection]]></g:title>
<g:description><![CDATA[This two-piece gift set contains Ambuscade (100ml) and Body Lotion (100ml), a rich cream that works to deeply nourish skin, helping leaving it soft and irresistibly scented.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hand-body/337-ambuscade-collection-760122168575.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1733-large_default/ambuscade-collection.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1660-large_default/ambuscade-collection.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1729-large_default/ambuscade-collection.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1715-large_default/ambuscade-collection.jpg]]></g:additional_image_link>
<g:price>134.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Hand & body]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[760122168575]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
</entry>

<entry>
<g:id>338</g:id>
<g:title><![CDATA[Caress Collection]]></g:title>
<g:description><![CDATA[This two-piece gift set contains Caress (100ml) and Body Lotion (100ml), a rich cream that works to deeply nourish skin, helping leaving it soft and irresistibly scented.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hand-body/338-caress-collection-760122168582.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1734-large_default/caress-collection.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1663-large_default/caress-collection.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1728-large_default/caress-collection.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1716-large_default/caress-collection.jpg]]></g:additional_image_link>
<g:price>127.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Hand & body]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[760122168582]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
</entry>

<entry>
<g:id>339</g:id>
<g:title><![CDATA[Pure Emerald Collection]]></g:title>
<g:description><![CDATA[This two-piece gift set contains Pure Emerald (100ml) and Body Lotion (100ml), a rich cream that works to deeply nourish skin, helping leaving it soft and irresistibly scented.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hand-body/339-pure-emerald-collection-760122168599.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1735-large_default/pure-emerald-collection.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1667-large_default/pure-emerald-collection.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1727-large_default/pure-emerald-collection.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1717-large_default/pure-emerald-collection.jpg]]></g:additional_image_link>
<g:price>132.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Hand & body]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[760122168599]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
</entry>

<entry>
<g:id>340</g:id>
<g:title><![CDATA[Avant Quintessence Collection]]></g:title>
<g:description><![CDATA[This two-piece gift set contains Avant Quintessence (100ml) and Body Lotion (100ml), a rich cream that works to deeply nourish skin, helping leaving it soft and irresistibly scented.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hand-body/340-quintessence-collection-760122168605.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1736-large_default/quintessence-collection.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1671-large_default/quintessence-collection.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1726-large_default/quintessence-collection.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1718-large_default/quintessence-collection.jpg]]></g:additional_image_link>
<g:price>137.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Hand & body]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[760122168605]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
</entry>

<entry>
<g:id>374</g:id>
<g:title><![CDATA[Probiotics Micro-Biota Fortifying Sensitive Face Serum]]></g:title>
<g:description><![CDATA[Our ultra-gentle serum helps to calm and hydrate irritated, red and sensitive skin. Formulated with most delicate yet effective ingredients the Probiotics Micro-Biota Fortifying Sensitive Face Serum helps quench and soothe the skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/sensitive/374-probiotics-micro-biota-fortifying-sensitive-face-serum-5060762542823.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1781-large_default/probiotics-micro-biota-fortifying-sensitive-face-serum.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1779-large_default/probiotics-micro-biota-fortifying-sensitive-face-serum.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1780-large_default/probiotics-micro-biota-fortifying-sensitive-face-serum.jpg]]></g:additional_image_link>
<g:price>92.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:product_type><![CDATA[Shop > By Range > Sensitive Skincare Collection]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762542823]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.183 匁</g:shipping_weight>
</entry>

<entry>
<g:id>375</g:id>
<g:title><![CDATA[Pro Refining Hyaluronic Antioxidising Sensitive Eye Cream]]></g:title>
<g:description><![CDATA[A mild, gentle eye cream that works to alleviate the signs of ageing such as crow's feet, fine lines and wrinkles on the most sensitive of complexions.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/sensitive/375-pro-refining-hyaluronic-antioxidising-sensitive-eye-cream-5060762542830.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1782-large_default/pro-refining-hyaluronic-antioxidising-sensitive-eye-cream.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1783-large_default/pro-refining-hyaluronic-antioxidising-sensitive-eye-cream.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1784-large_default/pro-refining-hyaluronic-antioxidising-sensitive-eye-cream.jpg]]></g:additional_image_link>
<g:price>79.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:product_type><![CDATA[Shop > By Range > Sensitive Skincare Collection]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762542830]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.059 匁</g:shipping_weight>
</entry>

<entry>
<g:id>376</g:id>
<g:title><![CDATA[Anti-fatigue Brightening Prebiotics Sensitive Eye Serum]]></g:title>
<g:description><![CDATA[Formulated with gentle, yet effective active ingredients our eye serum is a welcome addition to your skincare routine if you have sensitive skin. It works to delicately tighten the eye area and improve the appearance of fine lines.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/sensitive/376-anti-fatigue-brightening-prebiotics-sensitive-eye-serum-5060762542847.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1787-large_default/anti-fatigue-brightening-prebiotics-sensitive-eye-serum.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1785-large_default/anti-fatigue-brightening-prebiotics-sensitive-eye-serum.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1786-large_default/anti-fatigue-brightening-prebiotics-sensitive-eye-serum.jpg]]></g:additional_image_link>
<g:price>77.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:product_type><![CDATA[Shop > By Range > Sensitive Skincare Collection]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762542847]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.068 匁</g:shipping_weight>
</entry>

<entry>
<g:id>377</g:id>
<g:title><![CDATA[Hydrafirming & Toning Black Tea Sensitive Overnight Mask]]></g:title>
<g:description><![CDATA[A must-have addition to your overnight skincare routine if you're prone to sensitive skin. The high caffeine content in black tea works to gently firm skin whilst also helping fade blemishes, reduce pigmentation and soothe irritation.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/sensitive/377-hydrafirming-toning-black-tea-sensitive-overnight-mask-5060762542854.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1790-large_default/hydrafirming-toning-black-tea-sensitive-overnight-mask.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1788-large_default/hydrafirming-toning-black-tea-sensitive-overnight-mask.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1789-large_default/hydrafirming-toning-black-tea-sensitive-overnight-mask.jpg]]></g:additional_image_link>
<g:price>87.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:product_type><![CDATA[Shop > By Range > Sensitive Skincare Collection]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762542854]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.165 匁</g:shipping_weight>
</entry>

<entry>
<g:id>378</g:id>
<g:title><![CDATA[Probiotics Sensitive Pore Refining Exfoliator]]></g:title>
<g:description><![CDATA[Clarifying exfoliator designed to revive dull, tired sensitive skin. Active ingredients will work to gently buff away dead skin cells, helping leave skin purified and glowing.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/sensitive/378-probiotics-sensitive-pore-refining-exfoliator-5060762543301.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1793-large_default/probiotics-sensitive-pore-refining-exfoliator.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1791-large_default/probiotics-sensitive-pore-refining-exfoliator.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1792-large_default/probiotics-sensitive-pore-refining-exfoliator.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:product_type><![CDATA[Shop > By Range > Sensitive Skincare Collection]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762543301]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.164 匁</g:shipping_weight>
</entry>

<entry>
<g:id>379</g:id>
<g:title><![CDATA[Pro Sensitive Balancing Niacinamide Pore Tightening Toner]]></g:title>
<g:description><![CDATA[Formulated with Hyaluronic acid and Niacinamide, our Pro Sensitive Balancing Niacinamide Pore Tightening Toner will work to gently lock moisture into the skin, whilst purifying and clarifying the skin, thereby helping to allow better penetration of active ingredients in the rest of your skincare routine.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/sensitive/379-pro-sensitive-balancing-niacinamide-pore-tightening-toner-5060762543318.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1796-large_default/pro-sensitive-balancing-niacinamide-pore-tightening-toner.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1795-large_default/pro-sensitive-balancing-niacinamide-pore-tightening-toner.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1794-large_default/pro-sensitive-balancing-niacinamide-pore-tightening-toner.jpg]]></g:additional_image_link>
<g:price>72.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:product_type><![CDATA[Shop > By Range > Sensitive Skincare Collection]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762543318]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.359 匁</g:shipping_weight>
</entry>

<entry>
<g:id>380</g:id>
<g:title><![CDATA[Liquorice Sensitive Relief & Soothing Body Cream]]></g:title>
<g:description><![CDATA[This fast-absorbing, non-greasy sensitive body moisturiser works to gently protect the skin's natural barrier and lock in moisture.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/sensitive/380-liquorice-sensitive-relief-soothing-body-cream-5060762543325.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1800-large_default/liquorice-sensitive-relief-soothing-body-cream.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1798-large_default/liquorice-sensitive-relief-soothing-body-cream.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1799-large_default/liquorice-sensitive-relief-soothing-body-cream.jpg]]></g:additional_image_link>
<g:price>77.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:product_type><![CDATA[Shop > By Range > Sensitive Skincare Collection]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762543325]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.250 匁</g:shipping_weight>
</entry>

<entry>
<g:id>381</g:id>
<g:title><![CDATA[Sensitive PHA Pore Decongesting Cleansing Cream]]></g:title>
<g:description><![CDATA[A gentle cleanser designed for sensitive skin. Formulated with anti-inflammatory properties our sensitive cleanser will work to delicately banish impurities from the skin and remove stubborn waterproof makeup without damaging the skin barrier.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/sensitive/381-sensitive-pha-pore-decongesting-cleansing-cream-5060762543332.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1803-large_default/sensitive-pha-pore-decongesting-cleansing-cream.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1801-large_default/sensitive-pha-pore-decongesting-cleansing-cream.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1802-large_default/sensitive-pha-pore-decongesting-cleansing-cream.jpg]]></g:additional_image_link>
<g:price>72.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:product_type><![CDATA[Shop > By Range > Sensitive Skincare Collection]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762543332]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.250 匁</g:shipping_weight>
</entry>

<entry>
<g:id>382</g:id>
<g:title><![CDATA[Bio-Balancing Hyaluronic Sensitive Overnight Recovery Moisturiser]]></g:title>
<g:description><![CDATA[Soothe and revive delicate, sensitive skin with the Bio-Balancing Hyaluronic Sensitive Overnight Recovery Moisturiser. Combining the most gentle, yet effective ingredients this overnight moisturiser works to blanket the skin and helps your skin recover from exposure to environmental stressors and free radicals.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/sensitive/382-bio-balancing-hyaluronic-sensitive-overnight-recovery-moisturiser.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1775-large_default/bio-balancing-hyaluronic-sensitive-overnight-recovery-moisturiser.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1773-large_default/bio-balancing-hyaluronic-sensitive-overnight-recovery-moisturiser.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1774-large_default/bio-balancing-hyaluronic-sensitive-overnight-recovery-moisturiser.jpg]]></g:additional_image_link>
<g:price>82.00 GBP</g:price>
<g:sale_price>61.50 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:product_type><![CDATA[Shop > By Range > Sensitive Skincare Collection]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:identifier_exists>no</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.161 匁</g:shipping_weight>
</entry>

<entry>
<g:id>383</g:id>
<g:title><![CDATA[Prebiotics Calming Micro-Nutri Sensitive Day Moisturiser]]></g:title>
<g:description><![CDATA[This delicate, soothing day moisturiser is the perfect addition to your morning skincare regimen. It is designed to gently blanket sensitive skin, lock in moisture, and leave your complexion nourished and supple.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/moisturisers-mists/383-prebiotics-calming-micro-nutri-sensitive-day-moisturiser.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1778-large_default/prebiotics-calming-micro-nutri-sensitive-day-moisturiser.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1776-large_default/prebiotics-calming-micro-nutri-sensitive-day-moisturiser.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1777-large_default/prebiotics-calming-micro-nutri-sensitive-day-moisturiser.jpg]]></g:additional_image_link>
<g:price>82.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Moisturisers & Mists]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:identifier_exists>no</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.144 匁</g:shipping_weight>
</entry>

<entry>
<g:id>397</g:id>
<g:title><![CDATA[PHA Delicate Resurfacing & Refining Microfoliant]]></g:title>
<g:description><![CDATA[PHAs are loved for their ability to help gently break down dead skin cells with very minimal irritation. This microfoliant works to delicating buff away dead skin cells, improve skin tone and texture, and draw water to sensitive skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/masks-exfoliators/397-pha-delicate-resurfacing-refining-microfoliant-5060762542168.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1827-large_default/pha-delicate-resurfacing-refining-microfoliant.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1825-large_default/pha-delicate-resurfacing-refining-microfoliant.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1826-large_default/pha-delicate-resurfacing-refining-microfoliant.jpg]]></g:additional_image_link>
<g:price>77.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Masks & Exfoliators]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762542168]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.128 匁</g:shipping_weight>
</entry>

<entry>
<g:id>398</g:id>
<g:title><![CDATA[Protein-Complex Restructuring Niacinamide Defence Serum]]></g:title>
<g:description><![CDATA[By blending a powerful combination of ingredients such as Niacinamide and Tripeptides, this serum works to soften and energise skin whilst helping achieve clearer, brighter and smoother skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/serums/398-protein-complex-restructuring-niacinamide-defence-serum-5060762542625.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1830-large_default/protein-complex-restructuring-niacinamide-defence-serum.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1828-large_default/protein-complex-restructuring-niacinamide-defence-serum.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1829-large_default/protein-complex-restructuring-niacinamide-defence-serum.jpg]]></g:additional_image_link>
<g:price>79.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Serums]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762542625]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.043 匁</g:shipping_weight>
</entry>

<entry>
<g:id>399</g:id>
<g:title><![CDATA[Vino-Concentrate Refining Serum]]></g:title>
<g:description><![CDATA[By blending a powerful combination of ingredients such as Niacinamide and Tripeptides, this serum works to soften and energise skin whilst helping achieve clearer, brighter and smoother skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/serums/399-vino-concentrate-refining-serum-5060762542632.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1833-large_default/vino-concentrate-refining-serum.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1831-large_default/vino-concentrate-refining-serum.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1832-large_default/vino-concentrate-refining-serum.jpg]]></g:additional_image_link>
<g:price>72.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Serums]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762542632]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.047 匁</g:shipping_weight>
</entry>

<entry>
<g:id>410</g:id>
<g:title><![CDATA[Vitamin C Hydro-Lock Night Reset Mask]]></g:title>
<g:description><![CDATA[0This revolutionary mask works to give you brighter looking skin. The powerful blend of Vitamin C, Hyaluronic acid and Shea Butter is the perfect combination for waking up to more youthful, radiant and plumped, dewy skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/masks-exfoliators/410-vitamin-c-hydro-lock-night-reset-mask.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1836-large_default/vitamin-c-hydro-lock-night-reset-mask.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1834-large_default/vitamin-c-hydro-lock-night-reset-mask.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1835-large_default/vitamin-c-hydro-lock-night-reset-mask.jpg]]></g:additional_image_link>
<g:price>77.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Masks & Exfoliators]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:identifier_exists>no</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.068 匁</g:shipping_weight>
</entry>

<entry>
<g:id>411</g:id>
<g:title><![CDATA[In-Depth Recovery Collagen Hydra-Surge Overnight Mask]]></g:title>
<g:description><![CDATA[With benefits that work relentlessly to give you around the clock moisturisation, this night mask was designed to ensure you wake up with a refreshed, replenished and revitalised complexion.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/masks-exfoliators/411-in-depth-recovery-collagen-hydra-surge-overnight-mask--5060762542694.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1839-large_default/in-depth-recovery-collagen-hydra-surge-overnight-mask-.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1837-large_default/in-depth-recovery-collagen-hydra-surge-overnight-mask-.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1838-large_default/in-depth-recovery-collagen-hydra-surge-overnight-mask-.jpg]]></g:additional_image_link>
<g:price>79.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Masks & Exfoliators]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762542694]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.066 匁</g:shipping_weight>
</entry>

<entry>
<g:id>412</g:id>
<g:title><![CDATA[24-hour Moisture Boost Polyglutamic Conditioning Lip Serum]]></g:title>
<g:description><![CDATA[Give your dry lips some TLC with our 24-hour Moisture Boost Polyglutamic Conditioning Lip Serum. Formulated with Polyglutamic acid and Hyaluronic acid our lip serum is designed to deeply nourish and plump the lips and is a must-have for your lip care routine. ]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/lip-care/412-24-hour-moisture-boost-polyglutamic-conditioning-lip-serum.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1842-large_default/24-hour-moisture-boost-polyglutamic-conditioning-lip-serum.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1840-large_default/24-hour-moisture-boost-polyglutamic-conditioning-lip-serum.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1841-large_default/24-hour-moisture-boost-polyglutamic-conditioning-lip-serum.jpg]]></g:additional_image_link>
<g:price>52.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Lip Care]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:identifier_exists>no</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.026 匁</g:shipping_weight>
</entry>

<entry>
<g:id>413</g:id>
<g:title><![CDATA[AHA Gentle Exfoliating & Radiance Renewal Lip Serum]]></g:title>
<g:description><![CDATA[]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/lip-care/413-aha-gentle-exfoliating-radiance-renewal-lip-serum.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1845-large_default/aha-gentle-exfoliating-radiance-renewal-lip-serum.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1843-large_default/aha-gentle-exfoliating-radiance-renewal-lip-serum.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1844-large_default/aha-gentle-exfoliating-radiance-renewal-lip-serum.jpg]]></g:additional_image_link>
<g:price>79.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Lip Care]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:identifier_exists>no</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.026 匁</g:shipping_weight>
</entry>

<entry>
<g:id>414</g:id>
<g:title><![CDATA[Persian Sunset Radiance Body Lotion]]></g:title>
<g:description><![CDATA[A delicate, nourishing body lotion designed to soothe and reinvigorate the skin whilst immersing you in the scent of Persian Sunset.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hand-body/414-persian-sunset-radiance-body-lotion.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1847-large_default/persian-sunset-radiance-body-lotion.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1848-large_default/persian-sunset-radiance-body-lotion.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1846-large_default/persian-sunset-radiance-body-lotion.jpg]]></g:additional_image_link>
<g:price>72.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Hand & body]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:identifier_exists>no</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.128 匁</g:shipping_weight>
</entry>

<entry>
<g:id>415</g:id>
<g:title><![CDATA[Ambuscade Invigorating Body Lotion]]></g:title>
<g:description><![CDATA[A delicate, nourishing body lotion designed to soothe and reinvigorate the skin whilst emersing you in the scent of Ambuscade.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hand-body/415-ambuscade-invigorating-body-lotion.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1854-large_default/ambuscade-invigorating-body-lotion.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1852-large_default/ambuscade-invigorating-body-lotion.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1853-large_default/ambuscade-invigorating-body-lotion.jpg]]></g:additional_image_link>
<g:price>72.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Hand & body]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:identifier_exists>no</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.128 匁</g:shipping_weight>
</entry>

<entry>
<g:id>416</g:id>
<g:title><![CDATA[Caress Softening Body Lotion]]></g:title>
<g:description><![CDATA[A delicate, nourishing body lotion designed to soothe and reinvigorate the skin whilst emersing you in the scent of Caress.  Caress is a fragrance of synaesthesia, its scent evokes the sensuality of a gentle touch. It combines the fresh luxury of orchid with the comfort of high quality cashmere gliding over the skin. Its pale pink colour resembles that of fresh sheets and early sunrises. ]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hand-body/416-caress-softening-body-lotion.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1863-large_default/caress-softening-body-lotion.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1861-large_default/caress-softening-body-lotion.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1862-large_default/caress-softening-body-lotion.jpg]]></g:additional_image_link>
<g:price>72.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Hand & body]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:identifier_exists>no</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.128 匁</g:shipping_weight>
</entry>

<entry>
<g:id>417</g:id>
<g:title><![CDATA[Pure Emerald Awakening Body Lotion]]></g:title>
<g:description><![CDATA[A delicate, nourishing body lotion designed to soothe and reinvigorate the skin whilst emersing you in the scent of Pure Emerald. One spritz of Pure Emerald will transport you exactly where you want to go. It is a deep shade of green, linking the world of nature to that of luxury. Whether your idea of heaven is a secluded glade bejewelled with wildflowers or the sweetest cotton candy cloud at the seaside, this fragrance will take you there.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hand-body/417-pure-emerald-awakening-body-lotion.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1869-large_default/pure-emerald-awakening-body-lotion.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1867-large_default/pure-emerald-awakening-body-lotion.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1868-large_default/pure-emerald-awakening-body-lotion.jpg]]></g:additional_image_link>
<g:price>72.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Hand & body]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:identifier_exists>no</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.128 匁</g:shipping_weight>
</entry>

<entry>
<g:id>418</g:id>
<g:title><![CDATA[Avant Quintessence Enriching Body Lotion]]></g:title>
<g:description><![CDATA[A delicate, nourishing body lotion designed to soothe and reinvigorate the skin whilst emersing you in the scent of Quintessence. A semi-oriental floral amber fragrance, sweet, warm, with an elegant powdery base harmonised with flowers and a spicy note, this ‘fragrance-to-share’ is the very essence of sophistication: A modern classic. ]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hand-body/418-quintessence-enriching-body-lotion.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1875-large_default/quintessence-enriching-body-lotion.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1873-large_default/quintessence-enriching-body-lotion.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1874-large_default/quintessence-enriching-body-lotion.jpg]]></g:additional_image_link>
<g:price>72.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Hand & body]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:identifier_exists>no</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.128 匁</g:shipping_weight>
</entry>

<entry>
<g:id>419</g:id>
<g:title><![CDATA[Dual Response Pullulan Tightening & Firming Eye Cream]]></g:title>
<g:description><![CDATA[A revolutionary eye serum designed to help tighten whilst also rejuvenating and brightening the delicate eye area. This eye cream is fortified with Pullulan and Shea Butter and works to visibly reduce puffiness and the appearance of fine lines and dark circles for a noticeably youthful and refreshed eye contour.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/eye-care/419-dual-response-pullulan-tightening-firming-eye-cream-5060762542632.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1878-large_default/dual-response-pullulan-tightening-firming-eye-cream.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1876-large_default/dual-response-pullulan-tightening-firming-eye-cream.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1877-large_default/dual-response-pullulan-tightening-firming-eye-cream.jpg]]></g:additional_image_link>
<g:price>74.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Eye Care]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762542632]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.026 匁</g:shipping_weight>
</entry>

<entry>
<g:id>424</g:id>
<g:title><![CDATA[Antioxidising Micro-Algae Eye Protect Serum]]></g:title>
<g:description><![CDATA[Revolutionary skincare ingredient Micro-Algae works to hydrate, revitalise and tone the skin. The Antioxidising Micro-Algae Eye Protect Serum combines Micro-Algae and Magnolia Extract aimed at rejuvenating the eye area by helping eliminate the signs of ageing such as crow's feet and fine lines.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/eye-care/424-antioxidising-micro-algae-eye-protect-serum-5060762542632.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1923-large_default/antioxidising-micro-algae-eye-protect-serum.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1924-large_default/antioxidising-micro-algae-eye-protect-serum.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1925-large_default/antioxidising-micro-algae-eye-protect-serum.jpg]]></g:additional_image_link>
<g:price>74.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Eye Care]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[5060762542632]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.030 匁</g:shipping_weight>
</entry>

<entry>
<g:id>430</g:id>
<g:title><![CDATA[Avant Gift Box - Skincare Without Labels]]></g:title>
<g:description><![CDATA[Guaranteed to be an overnight sensation, Avant’s Skincare Without Labels Gift Set is a very special Limited-Edition collection of our best-selling products. An exquisite selection of beauty face creams that offer barrier repair-boosting hydration for the ultimate feeling of rejuvenation. Each of our 5-star rated face creams will flood your skin with moisture, stopping dry, dehydrated skin in its tracks, leaving you with dewy, luminous skin. Formulated to work intensively to boost your skincare routine day and night. Indulge in the super healing powers of our Skincare Without Labels Gift Set – available exclusively for a Limited Time only.  Includes:  Supreme Hyaluronic Acid Anti-oxidising DUO Moisturiser Deluxe Hyaluronic Acid Vivifying Face &amp; Eye Night Cream  8 hour Radiance Renewal Sleeping Mask]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/gift-boxes/430-avant-gift-box-skincare-without-labels-669203929251.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1957-large_default/avant-gift-box-skincare-without-labels.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1958-large_default/avant-gift-box-skincare-without-labels.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1959-large_default/avant-gift-box-skincare-without-labels.jpg]]></g:additional_image_link>
<g:price>280.00 GBP</g:price>
<g:sale_price>99.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Gift Boxes]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:gtin><![CDATA[669203929251]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
</entry>

<entry>
<g:id>431</g:id>
<g:title><![CDATA[Sumptuous Glycolic Acid Hand Balm]]></g:title>
<g:description><![CDATA[A Sumptuous Hand Balm aiming to restructure and regenerate hardened skin.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hand-body/431-sumptuous-glycolic-acid-hand-balm-655043795491-5060762540362.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/1966-large_default/sumptuous-glycolic-acid-hand-balm-655043795491.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1964-large_default/sumptuous-glycolic-acid-hand-balm-655043795491.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1965-large_default/sumptuous-glycolic-acid-hand-balm-655043795491.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1967-large_default/sumptuous-glycolic-acid-hand-balm-655043795491.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/1968-large_default/sumptuous-glycolic-acid-hand-balm-655043795491.jpg]]></g:additional_image_link>
<g:price>84.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Hand & body]]></g:product_type>
<g:gtin><![CDATA[5060762540362]]></g:gtin>
<g:identifier_exists>yes</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
<g:shipping_weight>0.070 匁</g:shipping_weight>
</entry>

<entry>
<g:id>452</g:id>
<g:title><![CDATA[Scent Discovery Collection]]></g:title>
<g:description><![CDATA[Limited-Time Offer: Order our Scent Discovery Collection and enjoy £24 OFF your favourite full-sized 100ml bottle! *Every order will receive an exclusive code to redeem*  This luxurious collection of five scents encapsulates the essence of timeless elegance. Indulge in a journey of olfactory delight with this exceptional gift set from Avant.  This gift set contains: Ambuscade,  2ml Caress, 2ml Pure Emerald, 2ml Avant Quintessence, 2mlPersian Sunset, 2ml]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/perfumes/452-scent-discovery-collection.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/2054-large_default/scent-discovery-collection.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2053-large_default/scent-discovery-collection.jpg]]></g:additional_image_link>
<g:price>24.00 GBP</g:price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Perfumes]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:identifier_exists>no</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
</entry>

<entry>
<g:id>458</g:id>
<g:title><![CDATA[Lunar New Year Gift Set Limited-Edition]]></g:title>
<g:description><![CDATA[Celebrate the season of love and glow big this Lunar New Year! Keep your skin feeling smooth and looking radiant all year long with our very special Limited-Edition Gift Set. Immerse yourself in our exclusive collection of cherished, award-winning skincare treasures, carefully selected to enhance your natural radiance and embrace the spirit of renewal. Celebrate Lunar New Year in radiant style with our exquisite beauty favourites, promising an infusion of love, prosperity, wellness, and glowing joy! Available for a Limited Time only. Includes: Deluxe Hyaluronic Acid Vivifying Face &amp; Eye Night Cream  R.N.A Radical Firmness Anti-Ageing Serum (Award Winning)  Beautifying Phytoactive Hair Bonding Elixir Brightening &amp; De-Puffing Hyaluronic Overnight Eye Recovery Mask 24-hour Moisture Boost Polyglutamic Conditioning Lip Serum]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/gift-boxes/458-lunar-new-year-gift-set-limited-edition.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/2088-large_default/lunar-new-year-gift-set-limited-edition.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2086-large_default/lunar-new-year-gift-set-limited-edition.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2085-large_default/lunar-new-year-gift-set-limited-edition.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2087-large_default/lunar-new-year-gift-set-limited-edition.jpg]]></g:additional_image_link>
<g:price>411.00 GBP</g:price>
<g:sale_price>129.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Gift Boxes]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:identifier_exists>no</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
</entry>

<entry>
<g:id>459</g:id>
<g:title><![CDATA[Supercharged Cocoa Rejuvenating Multitasker Serum SPF 30 Broad Spectrum]]></g:title>
<g:description><![CDATA[Launching soon - available to pre-order Introducing the groundbreaking Supercharged Cocoa Rejuvenating Multitasker Serum SPF 30 Broad Spectrum - redefining skincare by combining advanced technology and upcycled ingredients to create an all-in-one multifunctional solution. ]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/serums/459-supercharged-cocoa-rejuvenating-multitasker-serum-spf-30-broad-spectrum.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/2089-large_default/supercharged-cocoa-rejuvenating-multitasker-serum-spf-30-broad-spectrum.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2098-large_default/supercharged-cocoa-rejuvenating-multitasker-serum-spf-30-broad-spectrum.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2099-large_default/supercharged-cocoa-rejuvenating-multitasker-serum-spf-30-broad-spectrum.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2101-large_default/supercharged-cocoa-rejuvenating-multitasker-serum-spf-30-broad-spectrum.jpg]]></g:additional_image_link>
<g:price>124.00 GBP</g:price>
<g:sale_price>93.00 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Serums]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:identifier_exists>no</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
</entry>

<entry>
<g:id>460</g:id>
<g:title><![CDATA[Limited-Edition Wellness Face Cloth Set]]></g:title>
<g:description><![CDATA[Discover the ultimate care for your skin with our premium Cotton Face Cloth. To ensure optimal performance, we recommend a delicate hand wash and thorough rinsing twice a week using a mild soap. Afterward, gently wring out any excess water. To prevent dampness, hang the cloth in a warm, dry, and well-ventilated area between uses.]]></g:description>
<g:link><![CDATA[https://avant-skincare.com/en/hand-body/460-limited-edition-wellness-face-cloth-set.html]]></g:link>
<g:image_link><![CDATA[https://avant-skincare.com/2094-large_default/limited-edition-wellness-face-cloth-set.jpg]]></g:image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2093-large_default/limited-edition-wellness-face-cloth-set.jpg]]></g:additional_image_link>
<g:additional_image_link><![CDATA[https://avant-skincare.com/2092-large_default/limited-edition-wellness-face-cloth-set.jpg]]></g:additional_image_link>
<g:price>79.00 GBP</g:price>
<g:sale_price>59.25 GBP</g:sale_price>
<g:availability>in stock</g:availability>
<g:google_product_category><![CDATA[Health & Beauty > Personal Care > Cosmetics > Skin Care]]></g:google_product_category>
<g:product_type><![CDATA[Shop > By Category > Hand & body]]></g:product_type>
<g:brand><![CDATA[Avant Skincare]]></g:brand>
<g:identifier_exists>no</g:identifier_exists>
<g:condition><![CDATA[new]]></g:condition>
</entry>


</feed>`;
