import React, { useEffect, useState } from "react";
import Webcam, { WebcamProps } from "react-webcam";

interface IWebcamCapture {
  webcamRef: React.Ref<Webcam>;
  isCameraVisible: boolean;
  handleUserMedia: () => void;
  onCameraPermission: (granted: boolean) => void; // New prop to handle camera permission
}

const videoConstraints = {
  width: 660,
  height: 370,
  facingMode: "user",
  zoom: 1.1,
};

const WebcamCapture: React.FC<IWebcamCapture> = ({
  webcamRef,
  isCameraVisible,
  handleUserMedia,
  onCameraPermission, // Destructure the new prop
}) => {
  const [permissionChecked, setPermissionChecked] = useState(false);

  useEffect(() => {
    if (isCameraVisible && !permissionChecked) {
      navigator.mediaDevices
        .getUserMedia({ video: videoConstraints })
        .then((stream) => {
          onCameraPermission(true); // User granted permission
          handleUserMedia();
          setPermissionChecked(true);
          stream.getTracks().forEach((track) => track.stop()); // Stop the tracks after getting permission
        })
        .catch((err) => {
          onCameraPermission(false); // User denied permission
          setPermissionChecked(true);
        });
    }
  }, [isCameraVisible, handleUserMedia, onCameraPermission, permissionChecked]);

  return isCameraVisible ? (
    <Webcam
      screenshotFormat="image/jpeg"
      mirrored
      style={{ display: "block", margin: "auto", marginTop: "20px" }}
      onUserMedia={handleUserMedia}
      videoConstraints={videoConstraints as WebcamProps["videoConstraints"]}
      ref={webcamRef}
    />
  ) : null;
};

export default WebcamCapture;
