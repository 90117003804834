import React from "react";
import { Box, Typography } from "@mui/material";

interface BoxProps {
  text: string;
  dynamicText: string;
}

const CustomBox: React.FC<BoxProps> = ({ text, dynamicText }) => {
  return (
    <Box
      sx={{
        flex: 1,
        margin: "0 10px", 
        backgroundColor:
          dynamicText === "good"
            ? "green"
            : dynamicText === "ok"
            ? "orange"
            : "#F67A7A",

        borderRadius: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 45,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography sx={{fontSize: 13}} fontWeight="bold">{text}</Typography>
        <Typography>
          {dynamicText === "bad" ? "Not Good" : dynamicText}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomBox;
