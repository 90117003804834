// Header.tsx
import { styled } from "@mui/material";
import React from "react";

const logo = require("../../assets/logo.jpg");

const StyledHeader = styled("div")({
  textAlign: "center",
  marginTop: "10px",
  position: "relative",
});

const Logo = styled("img")({
  width: "100px",
  zIndex: 1,
  marginTop: 5
});



const Header: React.FC = () => {
  return (
    <StyledHeader>
      <Logo src={logo} alt="logo" />
    </StyledHeader>
  );
};

export default Header;
