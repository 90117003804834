import React from "react";
import { styled, Typography, Link, Box } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import PinterestIcon from "@mui/icons-material/Pinterest";
import InstagramIcon from "@mui/icons-material/Instagram";
import { grey } from "@mui/material/colors";

const FooterContainer = styled("footer")({
  backgroundColor: "#494448",
  color: "#fff",
  padding: "65px",
  textAlign: "center",
  marginTop: "150px",
});

const Logo = styled("img")({
  width: "50px",
  marginBottom: "20px",
});

const SocialIconsContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  gap: "20px",
  color: "white",
});

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <Logo
        src="https://avant-skincare.com/img/cms/Favicon white 500x500.png"
        alt="Company Logo"
        sx={{paddingBottom:10}}
      />
      <SocialIconsContainer>
        <Link href="https://www.facebook.com/avantskincare">
          <FacebookIcon fontSize="large" sx={{ color: grey[50] }} />
        </Link>
        <Link href="https://twitter.com/avantskincare">
          <TwitterIcon fontSize="large" sx={{ color: grey[50] }} />
        </Link>
        <Link href="https://www.pinterest.co.uk/pin/199636195971012987/">
          <PinterestIcon fontSize="large" sx={{ color: grey[50] }} />
        </Link>
        <Link href="https://www.instagram.com/avantskincare/">
          <InstagramIcon fontSize="large" sx={{ color: grey[50] }} />
        </Link>
      </SocialIconsContainer>
    </FooterContainer>
  );
};

export default Footer;
