import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { Typography, useMediaQuery, Link } from "@mui/material";
import "./index.css";
const fetch = require("node-fetch");

const FormContainer = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
});

const SuccessMessage = styled(Typography)({
  margin: "16px",
  color: "black",
});

interface IEmailForm {
  qa: any;
  templateParams: any;
}

const EmailForm: React.FC<IEmailForm> = ({ qa, templateParams }) => {
  const email = localStorage.getItem("email");
  const sendRoutine = localStorage.getItem("sendRoutine") === "true";
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const send = (event?: any) => {
    if (event) {
      event.preventDefault();
    }
    const url1 = `https://a.klaviyo.com/client/subscriptions/?company_id=${process.env.REACT_APP_COMPANY_ID}`;
    const options1 = {
      method: "POST",
      headers: { revision: "2024-02-15", "content-type": "application/json" },
      body: JSON.stringify({
        data: {
          type: "subscription",
          attributes: {
            profile: {
              data: {
                type: "profile",
                attributes: {
                  email: email,
                  properties: {
                    question1: qa.split(";")[0].split("answer:")[1],
                    question2: qa.split(";")[1].split("answer:")[1],
                    question3: qa.split(";")[2].split("answer:")[1],
                    total: templateParams.total,
                    wrinkles: templateParams.wrinkles,
                    firmness: templateParams.firmness,
                    redness: templateParams.redness,
                    darkSpots: templateParams.darkSpots,
                    oilPores: templateParams.oilPores,
                    texture: templateParams.texture,
                    product1: templateParams.product1Href,
                    product2: templateParams.product2Href,
                    product3: templateParams.product3Href,
                    product4: templateParams.product4Href,
                    product5: templateParams.product5Href,
                    product6: templateParams.product6Href,
                  },
                },
              },
            },
          },
          relationships: { list: { data: { type: "list", id: "TXtGj5" } } },
        },
      }),
    };

    const url = `https://a.klaviyo.com/client/events/?company_id=${process.env.REACT_APP_COMPANY_ID}`;
    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        revision: "2024-02-15",
      },
      body: JSON.stringify({
        data: {
          type: "event",
          attributes: {
            properties: templateParams,
            metric: {
              data: { type: "metric", attributes: { name: "Skin Analysis" } },
            },
            profile: {
              data: {
                type: "profile",
                attributes: {
                  email: email,
                  properties: {
                    question1: qa.split(";")[0].split("answer:")[1],
                    question2: qa.split(";")[1].split("answer:")[1],
                    question3: qa.split(";")[2].split("answer:")[1],
                    total: templateParams.total,
                    wrinkles: templateParams.wrinkles,
                    firmness: templateParams.firmness,
                    redness: templateParams.redness,
                    darkSpots: templateParams.darkSpots,
                    oilPores: templateParams.oilPores,
                    texture: templateParams.texture,
                    product1: templateParams.product1Href,
                    product2: templateParams.product2Href,
                    product3: templateParams.product3Href,
                    product4: templateParams.product4Href,
                    product5: templateParams.product5Href,
                    product6: templateParams.product6Href,
                  },
                },
              },
            },
          },
        },
      }),
    };

    fetch(url, options)
      .then((res: any) => {
        fetch(url1, options1)
          .then((res: any) => setShowSuccessMessage(true))
          .then((json: any) => console.log(json))
          .catch((err: any) => console.error("error:" + err));
      })
      .then((json: any) => console.log(json))
      .catch((err: any) => console.error("error:" + err));
  };
  useEffect(() => {
    if (sendRoutine) {
      send();
    }
  }, []);

  return (
    <FormContainer>
      <Typography
        variant="h4"
        sx={{
          marginTop: 10,
          marginBottom: 6,
          width: isSmallScreen ? 270 : 450,
          textAlign: "left",
          fontFamily: "'neue_hans_kendrickregular'",
        }}
      >
        Get Your Personalised Routine & 25% Off Voucher
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginTop: 1,
          marginBottom: 5,
          width: isSmallScreen ? 270 : 450,
          fontFamily: "'neue_hans_kendrickregular'",
        }}
      >
        Click "Email me" to get your results mailed to you. Plus, enjoy 25% off
        your first order,
        <Link
          target="_blank"
          href="https://avant-skincare.com/fr/content/3-terms-and-conditions-of-use"
          sx={{
            marginTop: 1,
            marginBottom: 5,
            width: isSmallScreen ? 270 : 450,
            fontFamily: "'neue_hans_kendrickregular'",
            fontSize: "1rem",
            color: "grey",
            textDecoration: "none",
          }}
        >
          {" "}
          T&Cs
        </Link>{" "}
        apply.
      </Typography>

      <button
        onClick={(event) => send(event)}
        className="button"
        disabled={localStorage.getItem("email") ? false : true}
      >
        Email me
      </button>
      {showSuccessMessage && (
        <SuccessMessage sx={{ marginTop: 5 }}>
          ✓ Successfully sent to your email.
        </SuccessMessage>
      )}
    </FormContainer>
  );
};

export default EmailForm;
