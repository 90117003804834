import { useState, useCallback } from "react";
import { xmlData } from "./products.js";
const XMLParser = require("react-xml-parser");

const useProducts = () => {
  const [products, setProducts] = useState<any>(null);

  const transformData = (data: any) => {
    const transformedData: any = {};

    data.forEach((item: any) => {
      const name = item.name.replace("g:", "").trim();
      const value = isNaN(parseFloat(item.value))
        ? item.value.replace(">", "").trim()
        : parseFloat(item.value.trim());

      transformedData[name] = value;
    });

    return transformedData;
  };

  const getProducts = useCallback(async () => {
    try {
      const xml = new XMLParser().parseFromString(xmlData);
      const res = xml.children
        .filter((item: any) => item.name === "entry")
        .map((entry: any) => transformData(entry.children));

      setProducts(res);
    } catch (error) {
      console.error("Error fetching or parsing data:", error);
    }
  }, []);

  return {
    products,
    getProducts,
  };
};

export default useProducts;
