import React from "react";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material";

interface SkinStatsProps {
  skinAnalysisResult: Array<any>;
  loading: boolean;
  controlFirmness: React.Dispatch<React.SetStateAction<boolean>>;
  controlTexture: React.Dispatch<React.SetStateAction<boolean>>;
  controlRedness: React.Dispatch<React.SetStateAction<boolean>>;
  controlWrinkles: React.Dispatch<React.SetStateAction<boolean>>;
  controlOilPores: React.Dispatch<React.SetStateAction<boolean>>;
  controlDarkSpots: React.Dispatch<React.SetStateAction<boolean>>;
}

const Container = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const AvatarContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const LoadingDots = styled(CircularProgress)`
  display: flex;
  gap: 4px;

  .MuiCircularProgress-circle {
    color: #000;
  }

  .MuiCircularProgress-circle:nth-of-type(1) {
    animation-delay: -0.5s;
  }

  .MuiCircularProgress-circle:nth-of-type(2) {
    animation-delay: -0.3s;
  }
`;

const TransparentBackgroundAvatar = styled(Avatar)`
  background: transparent;
  border: 2px solid ${(props) => props.color};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center the main number vertically */
`;

const MainNumber = styled("div")`
  font-size: 20px; /* Adjust the font size for the main number */
`;

const SecondaryNumber = styled("div")`
  font-size: 11px; /* Adjust the font size for the secondary number */
  margin-top: 2px; /* Add a minimal gap between the main number and the secondary number */
`;

const warmColors = ["#0CE51D", "pink", "red", "cyan", "#9DF40F", "#CD47F1"];

const SkinStats: React.FC<SkinStatsProps> = ({
  skinAnalysisResult,
  loading,
  controlFirmness,
  controlTexture,
  controlRedness,
  controlWrinkles,
  controlOilPores,
  controlDarkSpots,
}) => {
  const totalIndex = skinAnalysisResult.reduce((acc, _, index) => {
    return { ...acc, [index]: index };
  }, {} as Record<number, number>);

  const handleControls = (keyword: string) => {
    switch (keyword) {
      case "Firmness": {
        controlFirmness(true);
        controlTexture(false);
        controlRedness(false);
        controlWrinkles(false);
        controlDarkSpots(false);
        controlOilPores(false);
        break;
      }
      case "Texture": {
        controlFirmness(false);
        controlTexture(true);
        controlRedness(false);
        controlWrinkles(false);
        controlDarkSpots(false);
        controlOilPores(false);
        break;
      }
      case "Redness": {
        controlFirmness(false);
        controlTexture(false);
        controlRedness(true);
        controlWrinkles(false);
        controlDarkSpots(false);
        controlOilPores(false);
        break;
      }
      case "Wrinkles": {
        controlWrinkles(true);
        controlFirmness(false);
        controlTexture(false);
        controlRedness(false);
        controlDarkSpots(false);
        controlOilPores(false);
        break;
      }
      case "Oil & Pores": {
        controlWrinkles(false);
        controlFirmness(false);
        controlTexture(false);
        controlRedness(false);
        controlDarkSpots(false);
        controlOilPores(true);
        break;
      }
      case "Dark Spots": {
        controlFirmness(false);
        controlTexture(false);
        controlRedness(false);
        controlWrinkles(false);
        controlDarkSpots(true);
        controlOilPores(false);
        break;
      }

      default: {
        controlFirmness(true);
        controlTexture(true);
        controlRedness(true);
        controlWrinkles(true);
        controlDarkSpots(true);
        controlOilPores(true);
      }
    }
  };

  return (
    <>
      <Container>
        {skinAnalysisResult.map((item: any, index: any) => {
          return (
            <>
              <AvatarContainer
                key={index}
                onClick={() => handleControls(item.feature)}
              >
                <TransparentBackgroundAvatar
                  sx={{
                    border: `2px solid ${
                      warmColors[totalIndex[index] % warmColors.length]
                    }`,
                    width: 47,
                    height: 47,
                  }}
                >
                  {loading ? (
                    <LoadingDots size={24} />
                  ) : (
                    <>
                      <MainNumber>{item.score}</MainNumber>
                      <SecondaryNumber>/100</SecondaryNumber>
                    </>
                  )}
                </TransparentBackgroundAvatar>
                <div
                  style={{
                    color: "white",
                    textDecoration: "underline",
                    fontSize: "11px",
                    marginTop: 3,
                  }}
                >
                  {item.feature}
                </div>
              </AvatarContainer>
            </>
          );
        })}
      </Container>
    </>
  );
};

export default SkinStats;
