export const drawRedness = (
  context: any,
  points: any,
  color: string,
  fillOpacity: number = 1,
  arcParams: any,
) => {
  if (points.length < 2) {
    return;
  }

  context.beginPath();
  context.moveTo(points[0].x, points[0].y);

  for (let i = 1; i < points.length - 2; i += 1) {
    const xc = (points[i].x + points[i + 1].x) / 2;
    const yc = (points[i].y + points[i + 1].y) / 2;

    context.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
  }

  const xc = (points[points.length - 1].x + points[0].x) / 2;
  const yc = (points[points.length - 1].y + points[0].y) / 2;

  context.quadraticCurveTo(
    points[points.length - 1].x,
    points[points.length - 1].y,
    xc,
    yc
  );

  context.fillStyle = color;
  context.globalAlpha = fillOpacity;
  context.fill();

  context.globalCompositeOperation = "source-in";

  context.beginPath();

  context.arc(
    arcParams[0].centerX,
    arcParams[1].centerY,
    arcParams[2].radius,
    arcParams[3].startAngle,
    arcParams[4].endAngle
  );
  context.fill();

  context.globalCompositeOperation = "source-over";

  context.restore();
};
