import React, { createContext, useState } from "react";

interface ModelContextType {
  isModelLoaded: boolean;
  setIsModelLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModelLoadingContext = createContext<ModelContextType>({
  isModelLoaded: false,
  setIsModelLoaded: () => {},
});

interface IModelContext {
  children: any;
}

export const ModelLoadingProvider: React.FC<IModelContext> = ({ children }) => {
  const [isModelLoaded, setIsModelLoaded] = useState(false);

  return (
    <ModelLoadingContext.Provider value={{ isModelLoaded, setIsModelLoaded }}>
      {children}
    </ModelLoadingContext.Provider>
  );
};

export default ModelLoadingContext;
